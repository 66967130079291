import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const ConainersCleared20FootAnd40Foot = () => {
  const [isloading, setisloading] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading2, setLoading2] = useState(false);
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");

  useEffect(() => {
    AnalyzeBasedOnClient();
  }, [fromdate, todate]);

  const AnalyzeBasedOnClient = () => {
    setLoading2(true);
    const startDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log("startDatePass", startDatePass, "endDatePass", endDatePass);
    if (startDatePass && endDatePass) {
      axios
        .post(
          `${BASEURL}/containerscleard20ftand40ft`,
          {
            STARTDATE: startDatePass,
            ENDDATE: endDatePass,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };

  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>

          {activeDates && (
            <div className="date__custom">
              {/* Date filter buttons */}
              <div className="flex justify-between">
                <h1>Recommended</h1>
              </div>
              <button onClick={() => handleDateFilterChange("last3days")}>
                Last 3 Days
              </button>
              <button onClick={() => handleDateFilterChange("last7days")}>
                Last 7 Days
              </button>

              <button onClick={() => handleDateFilterChange("last30days")}>
                Last 30 Days
              </button>
              <h1>Calendar months</h1>
              <button onClick={() => handleDateFilterChange("lastMonth")}>
                Last Month
              </button>
              <button onClick={() => handleDateFilterChange("lastQuarter")}>
                Last Quarter
              </button>
              <button onClick={() => handleDateFilterChange("lastYear")}>
                Last Year
              </button>
              <h1>Custom date</h1>
              <main className="flex mx-1 gap-3">
                <div className="">
                  <div>
                    <p>Start Date :</p>
                  </div>
                  <div>
                    <input
                      value={fromdate.split("T")[0]}
                      type="date"
                      onClick={() => setActiveDates(true)}
                      onChange={(e) => {
                        setFromdate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <div>
                    <p>End Date :</p>
                  </div>
                  <div>
                    <input
                      value={todate.split("T")[0]}
                      type="date"
                      onClick={() => setActiveDates(true)}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          alert("Please select a valid todate");
                        } else {
                          setTodate(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </main>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "95%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {data && data.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>
                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                      },
                      xaxis: {
                        categories: data.map((item) =>
                          moment(item.PERIOD).format(" MMM YYYY")
                        ),
                        title: {
                          text: "PERIOD",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "CONTAINERS",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "TWENTY FOOT",
                        data: data.map((item) => item.TWENTYFOOT?.toFixed(2)),
                      },
                      {
                        name: "FORTY FOOT",
                        data: data.map((item) => item.FORTYFOOT?.toFixed(2)),
                      },
                    ]}
                    type="bar"
                    height={300}
                  />
                )}

                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart",
                      },
                      xaxis: {
                        categories: data.map((item) =>
                          moment(item.PERIOD).format(" MMM YYYY")
                        ),
                        title: {
                          text: "PERIOD",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "CONTAINERS",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "TWENTY FOOT",
                        data: data.map((item) =>
                          parseFloat(item?.TWENTYFOOT?.toFixed(2))
                        ),
                      },

                      {
                        name: "FORTY FOOT",
                        data: data.map((item) =>
                          parseFloat(item?.FORTYFOOT?.toFixed(2))
                        ),
                      },
                    ]}
                    type="area"
                    height={300}
                  />
                )}

                {chartType === "pie" && (
                  <div className="flex__pai">
                    <aside className="for__single__pai">
                      <ReactApexChart
                        options={{
                          labels: data.map((item) =>
                            moment(item.PERIOD).format(" MMM YYYY")
                          ),
                          title: {
                            text: " TWENTY FOOT",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = " TWENTY FOOT ";
                                return name + ": " + val;
                              },
                            },
                          },
                        }}
                        series={data.map((item) => item?.TWENTYFOOT)}
                        type="pie"
                        height={300}
                      />
                    </aside>
                    <aside className="for__single__pai">
                      <ReactApexChart
                        options={{
                          labels: data.map((item) =>
                            moment(item.PERIOD).format(" MMM YYYY")
                          ),
                          title: {
                            text: " FORTY FOOT ",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = " FORTY FOOT ";
                                return name + ": " + val;
                              },
                            },
                          },
                        }}
                        series={data.map((item) => item?.FORTYFOOT)}
                        type="pie"
                        height={300}
                      />
                    </aside>
                  </div>
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart",
                      },
                      xaxis: {
                        type: "category", // Assuming PERIOD represents discrete categories
                        labels: {
                          formatter: function (val) {
                            return moment(val).format(" MMM YYYY"); // Format date using moment.js
                          },
                          rotate: 0, // Adjust the rotation of the labels if needed
                        },
                        title: {
                          text: "PERIOD",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "CONTAINERS",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "TWENTY FOOT",
                        data: data.map((item) => ({
                          x: moment(item?.PERIOD).format(" MMM YYYY"),
                          y: item.TWENTYFOOT?.toFixed(2),
                        })),
                      },
                      {
                        name: "FORTY FOOT",
                        data: data.map((item) => ({
                          x: moment(item?.PERIOD).format(" MMM YYYY"),
                          y: item.FORTYFOOT?.toFixed(2),
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO DATA AVAILABLE
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ConainersCleared20FootAnd40Foot;
