import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import Select from "react-select"; // Import react-select
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
const ItemsCategoryStockValuation = () => {
  const [activeDates, setActiveDates] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selvehicleNo, setSelVehicleNo] = useState({
    value: "ALL",
    label: "ALL",
  }); // Default value set to "ALL"
  const [fromdate, setFromdate] = useState(new Date().toISOString());
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");

  useEffect(() => {
    AnalyzeBasedOnClient();
  }, [fromdate, todate, selvehicleNo]);

  const AnalyzeBasedOnClient = () => {
    const startDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log(
      selvehicleNo.value,
      "selvehicleNo.value",
      fromdate.slice(0, 10),
      "endDatePass"
    );
    if (startDatePass) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/stockanalysis`,
          {
            Todate: fromdate.slice(0, 10),
            SELECTBY: "SUBCATEGORY", // Using selvehicleNo value directly
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const filteredData = selectedRoute
    ? selectedRoute.value === "" // Check if "All" is selected
      ? data // Return all data if "All" is selected
      : data.filter((item) => item?.CATEGORY === selectedRoute.value)
    : data;
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = currentDate.toISOString(); // Set end date to current date by default
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const options = [
    { value: "last7days", label: "Last 7 Days" },
    { value: "last3days", label: "Last 3 Days" },
    { value: "last30days", label: "Last 30 Days" },
    { value: "lastMonth", label: "Last Month" },
    { value: "lastQuarter", label: "Last Quarter" },
    { value: "lastYear", label: "Last Year" },
    { value: "custom", label: "Custom Date" }, // Custom date option
  ];

  const filterRef = useRef(null); // Reference to the filter div

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div ref={filterRef}>
      <div className="align__date_1 lg:flex gap-[100px]">
        <div
          onClick={() => setActiveDates(!activeDates)}
          className="cursor-pointer flex items-center gap-2 "
        >
          <h2 className="text-2xl">
            {" "}
            <BsCalendar2Date />
          </h2>
          <span className="bg-white inline-block px-1 rounded border-1px border-solid">
            {fromdate.slice(8, 10) +
              "-" +
              fromdate.slice(5, 7) +
              "-" +
              fromdate.slice(0, 4)}
          </span>{" "}
          {/* -{" "}
          <span className="bg-white inline-block px-1 rounded">
            {todate.slice(8, 10) +
              "-" +
              todate.slice(5, 7) +
              "-" +
              todate.slice(0, 4)}
          </span> */}
        </div>
        <div className="lg:flex gap-2 items-center lg:ml-100px">
          <p className="mt-2  lg:mt-0  ">SUBCATEGORY &nbsp;</p>
          <div>
            <Select
              className="route_select"
              value={selectedRoute}
              onChange={(selectedOption) => {
                setSelectedRoute(selectedOption);
              }}
              options={[
                { value: "", label: "All" }, // Option to return all data
                ...data.map((item) => ({
                  value: item?.CATEGORY,
                  label: item?.CATEGORY,
                })),
              ]}
            />
          </div>
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* 
              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div> */}
            </main>
          </div>
        )}
      </div>
      {/* <div className="align__date_1">
        <div className="list_start">
          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              className="input_list_end"
              value={todate.split("T")[0]}
              type="date"
              onChange={(e) => {
                setTodate(e.target.value);
              }}
            />
          </div>
        </div>
      </div> */}
      <div
        style={{ width: "95%", margin: "0 auto", marginTop: "15px" }}
        onClick={() => setActiveDates(false)}
      >
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {data && filteredData.length > 0 ? (
              <div>
                {" "}
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart", // Change the ID for the bar chart
                      },
                      xaxis: {
                        categories: filteredData.map((item) =>
                          item?.CATEGORY.slice(0, 15)
                        ),
                        title: {
                          text: "SUBCATEGORY", // X-axis label
                        },
                      },
                      yaxis: {
                        title: {
                          text: "AMOUNT", // Y-axis label
                        },
                      },
                      dataLabels: {
                        enabled: false, // Disable the data labels for bar chart
                      },
                    }}
                    series={[
                      {
                        name: "STOCKVALUE",
                        data: filteredData.map((item) => item?.STOCKVALUE),
                      },
                    ]}
                    type="bar" // Change chart type to "bar"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <ReactApexChart
                    options={{
                      labels: filteredData.map((item) => item?.CATEGORY), // Labels for each segment
                      title: {
                        text: "Pie Chart", // Title of the pie chart
                      },
                      dataLabels: {
                        enabled: true, // Enable data labels for pie chart
                      },
                      tooltip: {
                        enabled: true,
                        y: {
                          formatter: function (val) {
                            return "STOCKVALUE: " + val; // Custom tooltip formatter
                          },
                        },
                      },
                    }}
                    series={filteredData.map((item) => item?.STOCKVALUE)} // Data for each segment
                    type="pie" // Set chart type to "pie"
                    height={300}
                  />
                )}
                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      xaxis: {
                        type: "SUBCATEGORY",
                        categories: filteredData.map((item) => item?.CATEGORY),
                        title: {
                          text: "SUBCATEGORY", // X-axis label
                        },
                      },
                      yaxis: {
                        title: {
                          text: "AMOUNT", // Y-axis label
                        },
                      },
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "STOCKVALUE",
                        data: filteredData.map((item) => ({
                          x: item?.CATEGORY, // X-coordinate
                          y: item?.STOCKVALUE, // Y-coordinate
                        })),
                      },
                    ]}
                    type="scatter" // Set chart type to "scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                {selvehicleNo && <p> No Data Available</p>}
                {!selvehicleNo && <p> SELECT AMOUNT</p>}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ItemsCategoryStockValuation;
