import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";

import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";

import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
// import TransportDashboardsNavbar from "../../../Components/TransportDashboardsNavbar";
import { useDispatch, useSelector } from "react-redux";
// import { selectUser } from "../../../Components/features/userSlice";
import { selectUser } from "../../../../Components/features/userSlice";
import ContainersClearedPeriodic from "./ContainersClearedPeriodic";
import ConainersCleared20FootAnd40Foot from "./ConainersCleared20FootAnd40Foot";
import LooseCargoLoads from "./LooseCargoLoads";
import ClientWiseContainersCleared from "./ClientWiseContainersCleared";
import ClientWiseContainersClearedforSelectedClient from "./ClientWiseContainersClearedforSelectedClient";
import CNFNavbar from "../../../../Components/CNFNavbar";
const AnalyticsDashBoardCNF = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "ContainersClearedPeriodic",
    label: "Containers Cleared Periodic",
  });
  const options = [
    {
      value: "ContainersClearedPeriodic",
      label: "Containers Cleared Periodic",
    },
    {
      value: "ConainersCleared20FootAnd40Foot",
      label: "Conainers Cleared 20 Foot And 40 Foot",
    },
    {
      value: "LooseCargoLoads",
      label: "Loose Cargo Loads",
    },
    {
      value: "ClientWiseContainersCleared",
      label: "Client Wise Containers Cleared",
    },
    {
      value: "ClientWiseContainersClearedforSelectedClient",
      label: "Client Wise Containers Cleared For Selected Client",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <CNFNavbar />

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Analytics DashBoard</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "ContainersClearedPeriodic" && (
            <ContainersClearedPeriodic />
          )}
        {selectedComponent &&
          selectedComponent.value === "ConainersCleared20FootAnd40Foot" && (
            <ConainersCleared20FootAnd40Foot />
          )}
        {selectedComponent && selectedComponent.value === "LooseCargoLoads" && (
          <LooseCargoLoads />
        )}
        {selectedComponent &&
          selectedComponent.value === "ClientWiseContainersCleared" && (
            <ClientWiseContainersCleared />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "ClientWiseContainersClearedforSelectedClient" && (
            <ClientWiseContainersClearedforSelectedClient />
          )}
      </div>
    </div>
  );
};

export default AnalyticsDashBoardCNF;
