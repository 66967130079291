import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import "../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
// import LoadingScreen from "../../../Components/LoadingScreen";
// import Loading2 from "../../../Components/Loading2";
// import "../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import AnalyticsBasedOnCategory from "../PurchaseAnalytics/AnalyticsBasedOnCategory";
import AnalyticsBasedOnSubCategory from "../PurchaseAnalytics/AnalyticsBasedOnSubCategory";
import AnalyticsBasedOnSuppliername from "../PurchaseAnalytics/AnalyticsBasedOnSuppliername";
import AnalyticsBasedOnPurechaseType from "../PurchaseAnalytics/AnalyticsBasedOnPurechaseType";
import AnalyticsBasedOnDivission from "../PurchaseAnalytics/AnalyticsBasedOnDivission";
import MonthwisePurchases from "../PurchaseAnalytics/MonthwisePurchases";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
function PurchasesDashboard() {
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "AnalyticsBasedOnCategory",
    label: "Analytics Based On Category",
  });
  const options = [
    {
      value: "AnalyticsBasedOnCategory",
      label: "Analytics Based On Category",
    },
    {
      value: "AnalyticsBasedOnSubCategory",
      label: "Analytics Based On SubCategory",
    },
    {
      value: "AnalyticsBasedOnSuppliername",
      label: "Analytics Based On Supplier name",
    },

    {
      value: "AnalyticsBasedOnPurchaseType",
      label: "Analytics Based On Purchase Type",
    },
    {
      value: "AnalyticsBasedOnDivission",
      label: "Analytics Based On Divission",
    },
    {
      value: "MonthwisePurchases",
      label: "Month wise Purchases",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };
  const navigate = useNavigate();
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [VehicleNo, setVehicleNo] = useState([]);
  var [selvehicleNo, setSelVehicleNo] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [data, setData] = useState([]);
  const [datawithVehicleNo, setDataWithVehicleNo] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [driver, setDriver] = useState([]);
  var [seldrivecode, setselDriveCode] = useState("");
  var [seldrivername, setseldrivername] = useState("");
  const [datawithvehinoanddrivercode, setdatawithvehinoanddrivercode] =
    useState([]);
  const [datawithdrivercode, setDatawithdrivercode] = useState([]);

  useEffect(() => {
    // getVehicleNo();
    // getDriver();
    // AnalyzeBasedOnVehicleNo();
    setisloading(false);
  }, [selvehicleNo, fromdate, todate, seldrivecode, seldrivername]);

  const getVehicleNo = () => {
    axios
      .get(`${BASEURL}/VehicleNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
      });
  };

  const getDriver = () => {
    axios
      .get(`${BASEURL}/DriverDetailss`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setDriver(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };
  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = driver.find(
      (obj) => obj.EMPLOYEENAME === selectedValue
    );
    if (selectedValue === "") {
      setseldrivername("");
      setselDriveCode("");
    } else if (selectedOption === "") {
      setselDriveCode("");
      setseldrivername("");
    } else {
      setseldrivername(selectedValue);
      setselDriveCode(selectedOption.OT_JOB_DRIVER_CODE);
    }
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = driver.find(
      (obj) => obj.OT_JOB_DRIVER_CODE === selectedValue
    );
    if (selectedValue === "") {
      setseldrivername("");
      setselDriveCode("");
    } else if (selectedOption === "") {
      setseldrivername("");
      setselDriveCode("");
    } else {
      setselDriveCode(selectedValue);
      setseldrivername(selectedOption.EMPLOYEENAME);
    }
  };

  const AnalyzeBasedOnVehicleNo = () => {
    if (selvehicleNo === "" && seldrivecode === "") {
      selvehicleNo = "null";
      seldrivecode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsOnListOfVehiclesInWorkshop/${selvehicleNo}/${seldrivecode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selvehicleNo !== "" && seldrivecode === "") {
      seldrivecode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsOnListOfVehiclesInWorkshop/${selvehicleNo}/${seldrivecode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithVehicleNo(response.data);
          console.log(response);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selvehicleNo === "" && seldrivecode !== "") {
      selvehicleNo = "null";
      axios
        .post(
          `${BASEURL}/AnalyticsOnListOfVehiclesInWorkshop/${selvehicleNo}/${seldrivecode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithdrivercode(response.data);
          console.log(response);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (selvehicleNo !== "" && seldrivecode !== "") {
      axios
        .post(
          `${BASEURL}/AnalyticsOnListOfVehiclesInWorkshop/${selvehicleNo}/${seldrivecode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setdatawithvehinoanddrivercode(response.data);
          console.log(response);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  // if (isloading) {
  //   return <LoadingScreen />;
  // }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="flex items-center gap-[50px]">
          <p className="text-white">
            Logged in as : {userEmail.split("@")[0].toUpperCase()}
          </p>
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/AnalyticsDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Purchase Analytics</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnCategory" && (
            <AnalyticsBasedOnCategory />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnSubCategory" && (
            <AnalyticsBasedOnSubCategory />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnSuppliername" && (
            <AnalyticsBasedOnSuppliername />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnPurchaseType" && (
            <AnalyticsBasedOnPurechaseType />
          )}
        {selectedComponent &&
          selectedComponent.value === "MonthwisePurchases" && (
            <MonthwisePurchases />
          )}
        {selectedComponent &&
          selectedComponent.value === "AnalyticsBasedOnDivission" && (
            <AnalyticsBasedOnDivission />
          )}
      </div>
    </div>
  );
}

export default PurchasesDashboard;
