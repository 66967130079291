import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../Components/LoadingScreen";
import Loading2 from "../../../Components/Loading2";

const VehicleExpense = () => {
  const today = new Date(); // Get the current date

  const startOfMonth = new Date(today.getFullYear(), 0, 2);

  const [startdate, setStartdate] = useState(startOfMonth.toISOString());
  const [enddate, setEnddate] = useState(new Date().toISOString());

  const [vehicle, setVehicle] = useState([]);
  var [selvehicle, setselVehicle] = useState("");
  const [vehiclemake, setVehiclemake] = useState([]);
  var [selvehiclemake, setselVehiclemake] = useState("");
  const [costtype, setCostType] = useState([]);
  const [data, setData] = useState([]);
  const [datawithvehicle, setDatawithVehicle] = useState([]);
  const [datawithvehiclemake, setDatawithVehiclemake] = useState([]);
  const [datacosttype, setDataCostType] = useState([]);
  const [datawithvehiandvehimake, setDatawithVehiandvehimake] = useState([]);
  const [datawithvehimakeandcost, setDatawithVehimakeandCost] = useState([]);
  const [datawithcostandvehi, setDatawithCostandvehi] = useState([]);
  const [datavmc, setDatavmc] = useState([]);
  var [selcosttype, setselCostType] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);

  useEffect(() => {
    postVehicle();
    postVehiclemake();
    postcosttype();
    CostofTruckExpense();
    setisloading(false);
  }, [startdate, enddate, selcosttype, selvehicle, selvehiclemake]);

  const postVehicle = () => {
    axios
      .post(
        `${BASEURL}/GettingVehicle`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setVehicle(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postVehiclemake = () => {
    axios
      .post(
        `${BASEURL}/GettingVehicleMake`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setVehiclemake(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postcosttype = () => {
    axios
      .post(
        `${BASEURL}/GettingCostType`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setCostType(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const CostofTruckExpense = () => {
    if (selcosttype === "" && selvehicle === "" && selvehiclemake === "") {
      selcosttype = "null";
      selvehicle = "null";
      selvehiclemake = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype !== "" &&
      selvehicle === "" &&
      selvehiclemake === ""
    ) {
      selvehiclemake = "null";
      selvehicle = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataCostType(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype === "" &&
      selvehicle !== "" &&
      selvehiclemake === ""
    ) {
      selvehiclemake = "null";
      selcosttype = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithVehicle(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype === "" &&
      selvehicle === "" &&
      selvehiclemake !== ""
    ) {
      selvehicle = "null";
      selcosttype = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithVehiclemake(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype !== "" &&
      selvehicle !== "" &&
      selvehiclemake === ""
    ) {
      selvehiclemake = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithCostandvehi(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype !== "" &&
      selvehicle === "" &&
      selvehiclemake !== ""
    ) {
      selvehicle = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithVehimakeandCost(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype === "" &&
      selvehicle !== "" &&
      selvehiclemake !== ""
    ) {
      selcosttype = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithVehiandvehimake(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selcosttype !== "" &&
      selvehicle !== "" &&
      selvehiclemake !== ""
    ) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/CostOfTruckExpenses/${selcosttype}/${selvehicle}/${selvehiclemake}`,
          {
            startdate: moment(startdate).format("DD/MM/YYYY"),
            enddate: moment(enddate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatavmc(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      {" "}
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/TransportDashboard1"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        {/* <h2>Cost of Truck Expenses</h2> */}
        <h2>Cost Analytics</h2>
      </div>
      <div className="clearbutton__allPage">
        <button
          className=""
          onClick={() => {
            window.location.reload();
          }}
        >
          Clear
        </button>
      </div>
      <div className="align__date_1">
        <div className="list1_start">
          <div>
            <p>Start Date :</p>
          </div>
          <div>
            <input
              className="input_list_start"
              value={startdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid fromdate");
                } else {
                  setStartdate(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="list1_of_vehi">
          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              className="input_list_end"
              value={enddate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid todate");
                } else {
                  setEnddate(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="Supp_analytics_Supp_Name">
          <div
            className="analytics_Category_select vehi_no"
            style={{ zIndex: "100" }}
          >
            <aside>
              <p>Vehicle Number :</p>
            </aside>
            <Select
              className="Select_pay_mode"
              onChange={(e) => {
                //   if (data.length <= 0) {
                //     alert("There is no data to sort!");
                //   } else {
                setselVehicle(e.value);
                //   if (e.value === "") {
                // setDataWithVehicleNo([]);
              }}
              value={
                selvehicle
                  ? { label: selvehicle, value: selvehicle }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...vehicle.map((item) => ({
                  value: item.VEHICLE,
                  label: item.VEHICLE,
                })),
              ]}
            />
          </div>
        </div>
      </div>
      <div className="Supp_analytics_Supp_Name">
        <div className="analytics_Category_select vehi_make">
          <aside>
            <p>Vehicle Make :</p>
          </aside>
          <Select
            className="Select_pay_mode"
            onChange={(e) => {
              // if (data.length <= 0) {
              //   alert("There is no data to sort!");
              // } else {
              setselVehiclemake(e.value);
              // if (e.value === "") {
              //   setDataWithVehicleNo([]);
              // }
            }}
            value={
              selvehiclemake
                ? { label: selvehiclemake, value: selvehiclemake }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...vehiclemake.map((item) => ({
                value: item.VEHICLEMAKE,
                label: item.VEHICLEMAKE,
              })),
            ]}
          />
        </div>
        <div className="analytics_Category_2_select ">
          <aside>
            <p className="vehi_cost">Cost Type :</p>
          </aside>
        </div>
        <div>
          <Select
            className="Select_Category select"
            onChange={(selectedOption) => {
              //   if (data.length <= 0) {
              //     alert("There is no data to sort!");
              //   } else {
              setselCostType(selectedOption.value);
            }}
            value={
              selcosttype
                ? { label: selcosttype, value: selcosttype }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...costtype.map((item) => ({
                value: item.COSTTYPE,
                label: item.COSTTYPE,
              })),
            ]}
          />
        </div>
      </div>
      <div style={{ width: "80%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {selcosttype &&
            !selvehicle &&
            !selvehiclemake &&
            datacosttype.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datacosttype.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datacosttype.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selcosttype &&
              selvehicle &&
              !selvehiclemake &&
              datawithvehicle.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithvehicle.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datawithvehicle.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selcosttype &&
              !selvehicle &&
              selvehiclemake &&
              datawithvehiclemake.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithvehiclemake.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datawithvehiclemake.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : selcosttype &&
              selvehicle &&
              !selvehiclemake &&
              datawithcostandvehi.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithcostandvehi.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datawithcostandvehi.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : selcosttype &&
              !selvehicle &&
              selvehiclemake &&
              datawithvehimakeandcost.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithvehimakeandcost.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datawithvehimakeandcost.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selcosttype &&
              selvehicle &&
              selvehiclemake &&
              datawithvehiandvehimake.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithvehiandvehimake.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datawithvehiandvehimake.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : selcosttype &&
              selvehicle &&
              selvehiclemake &&
              datavmc.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datavmc.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: datavmc.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selcosttype &&
              !selvehicle &&
              !selvehiclemake &&
              data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) => item.COSTTYPE),
                    title: {
                      text: "Vehicle", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Cost",
                    data: data.map((item) => item.COST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default VehicleExpense;
