import React from "react";
import { useNavigate } from "react-router-dom";

// import Footer from "./Components/Footer";

import workshop from "../../../Images/workshop.PNG";
import analyticscnf from "../../../Images/analyticscnf.PNG";
import incomeandExpenses from "../../../Images/incomeandExpenses.PNG";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../Components/features/userSlice";
import AllPagesMasterNavbar from "../../../Components/MasterNavbar/AllPagesMasterNavbar";
function CNF() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  return (
    <div>
      <AllPagesMasterNavbar />

      <div className="h2">
        <h2>Welcome to CNF Dashboards</h2>
      </div>

      <section
        className="for__main__dashboard flex flex-wrap "
        style={{ fontSize: "12px" }}
      >
        <div
          onClick={() => {
            navigate("/IncomeAndExpenses");
          }}
          className="align__img__and__heading"
        >
          <img
            src={incomeandExpenses}
            height="150px"
            width="180px"
            alt="dashboard"
          />
          <h3>Income And Expenses</h3>
        </div>

        <div
          onClick={() => {
            navigate("/AnalyticsDashBoardCNF");
          }}
          className="align__img__and__heading"
        >
          <img
            src={analyticscnf}
            height="150px"
            width="180px"
            alt="dashboard"
          />
          <h3>Analytics Dashboard</h3>
        </div>
      </section>
    </div>
  );
}

export default CNF;
