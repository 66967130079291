import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Footer from "../../../../Components/Footer";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";

const AnalyticsBasedOnItem = () => {
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [data, setData] = useState([]);
  const [datawithitem, setDatawithitem] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  var [selItemCode, setSelItemCode] = useState("");
  const [selItemName, setSelItemName] = useState("");
  const [product, setProduct] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);

  useEffect(() => {
    AnalyzeBasedOnProduct();
    getProduct();
    setisloading(false);
  }, [selItemCode, selItemName, fromdate, todate]);

  const getProduct = () => {
    axios
      .get(`${BASEURL}/Item`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };

  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = product.find(
      (obj) => obj.OM_ITEM_DESCRIPTION === selectedValue
    );
    if (selectedValue === "") {
      setSelItemName("");
      setSelItemCode("");
      setDatawithitem([]);
    } else if (selectedOption === "") {
      setSelItemCode("");
      setSelItemName("");
      setDatawithitem([]);
    } else {
      setSelItemName(selectedValue);
      setSelItemCode(selectedOption.OM_ITEM_CODE);
    }
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = product.find(
      (obj) => obj.OM_ITEM_CODE === selectedValue
    );
    if (selectedValue === "") {
      setSelItemName("");
      setSelItemCode("");
      setDatawithitem([]);
    } else if (selectedOption === "") {
      setSelItemName("");
      setSelItemCode("");
      setDatawithitem([]);
    } else {
      setSelItemCode(selectedValue);
      setSelItemName(selectedOption.OM_ITEM_DESCRIPTION);
    }
  };

  const AnalyzeBasedOnProduct = () => {
    if (selItemCode === "") {
      selItemCode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnItem/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnItem/${selItemCode}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDatawithitem(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {/* <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/SALESANALYSIS"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div> */}
      {/*  */}
      <section className=" justify-between p-2">
        <div className="template__card w-[100%]">
          {" "}
          <div className="flex justify-between gap-1">
            <div className=" py-1">
              <p className="w-[100px]">Start Date</p>

              <input
                value={fromdate.split("T")[0]}
                type="date"
                onChange={(e) => {
                  if (e.target.value === "") {
                    alert("Please select a valid fromdate");
                  } else {
                    setFromdate(e.target.value);
                  }
                }}
              />
            </div>
            <div className=" py-1">
              <p className="w-[100px]">End Date</p>

              <input
                value={todate.split("T")[0]}
                type="date"
                onChange={(e) => {
                  if (e.target.value === "") {
                    alert("Please select a valid todate");
                  } else {
                    setTodate(e.target.value);
                  }
                }}
              />
            </div>
            <div className=" items-center ">
              <p className="w-[120px]">Item Code</p>

              <Select
                className="Select_item_code"
                onChange={(selectedOption) => {
                  if (data.length <= 0) {
                    alert("There is no data to sort!");
                  } else {
                    handleItemCode(selectedOption.value);
                  }
                }}
                value={
                  selItemCode
                    ? { label: selItemCode, value: selItemCode }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...product.map((item) => ({
                    value: item.OM_ITEM_CODE,
                    label: item.OM_ITEM_CODE,
                  })),
                ]}
              />
            </div>
            <div className=" items-center">
              <p className="w-[120px]">Item Name</p>

              <Select
                className="Select_item_name"
                onChange={(selectedOption) => {
                  if (data.length <= 0) {
                    alert("There is no data to sort!");
                  } else {
                    handleItemName(selectedOption.value);
                  }
                }}
                value={
                  selItemName
                    ? { label: selItemName, value: selItemName }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...product.map((item) => ({
                    value: item.OM_ITEM_DESCRIPTION,
                    label: item.OM_ITEM_DESCRIPTION,
                  })),
                ]}
              />
            </div>{" "}
          </div>
        </div>
      </section>
      {/* <div className="align__date">
        <div className="">
          <div>
            <p>Start Date :</p>
          </div>
          <div>
            <input
              value={fromdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid fromdate");
                } else {
                  setFromdate(e.target.value);
                }
              }}
            />
          </div>

          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              value={todate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid todate");
                } else {
                  setTodate(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="Product_analytics_item">
          <div className="analytics_product_select">
            <aside>
              <p>Item Code :</p>
            </aside>
            <Select
              className="Select_item_code"
              onChange={(selectedOption) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  handleItemCode(selectedOption.value);
                }
              }}
              value={
                selItemCode
                  ? { label: selItemCode, value: selItemCode }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...product.map((item) => ({
                  value: item.OM_ITEM_CODE,
                  label: item.OM_ITEM_CODE,
                })),
              ]}
            />
          </div>
          <div className="analytics_product_select">
            <aside>
              <p>Item Name :</p>
            </aside>
            <Select
              className="Select_item_name"
              onChange={(selectedOption) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  handleItemName(selectedOption.value);
                }
              }}
              value={
                selItemName
                  ? { label: selItemName, value: selItemName }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...product.map((item) => ({
                  value: item.OM_ITEM_DESCRIPTION,
                  label: item.OM_ITEM_DESCRIPTION,
                })),
              ]}
            />
          </div>
        </div>
      </div> */}

      <div style={{ width: "90%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {" "}
            {selItemCode && datawithitem.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitem.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Revenue", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Revenue",
                    data: datawithitem.map((item) => item.AMOUNT),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : !selItemCode && data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) => item["ITEM DESCRITPTION"]),
                    title: {
                      text: "Item Name", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Revenue", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Revenue",
                    data: data.map((item) => item.AMOUNT),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AnalyticsBasedOnItem;
