import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import Loading from "./Components/LoadingScreen";
import { Link } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  setSuperUserAccounts,
  setExclusiveRights,
  setSelectedDepartment,
  setDataFetched,
} from "./Components/features/userSlice";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import "jspdf-autotable"; // Importing the AutoTable plugin
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaLessThan } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa";
import AllPagesMasterNavbar from "../src/Components/MasterNavbar/AllPagesMasterNavbar";

const TRUCKSSTATUS = () => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const userEmail = user.email;

  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("session-token");
  const [search, setSearch] = useState("");
  const [DataList, setDataList] = useState([]);
  const [isloading, setisloading] = useState(false);
  // State variables for datasets
  const [openJobCardsData, setOpenJobCardsData] = useState([]);
  const [jobCardsInProgressData, setJobCardsInProgressData] = useState([]);
  const [completedJobCardsData, setCompletedJobCardsData] = useState([]);
  const [BreakdownlistData, setBreakdownlistData] = useState([]);

  // State to track current view
  const [currentView, setCurrentView] = useState("OpenJobCards"); // Default to OpenJobCards
  const [intervalId, setIntervalId] = useState(null);
  console.log(currentView, "currentView currentView");

  // Fetch mock data on mount (replace with real API calls)

  // Start interval on component mount
  useEffect(() => {
    // const id = setInterval(() => handleNext(), 10000);
    const id = setInterval(() => handleNext(), 300000); // 5 minutes
    setIntervalId(id);

    // Cleanup on unmount
    return () => clearInterval(id);
  }, [currentView]);

  // Navigate to the next dataset
  const handleNext = () => {
    FetchDetails();
    clearInterval(intervalId); // Stop interval on manual navigation
    setCurrentView((prev) => {
      if (prev === "OpenJobCards") return "JobCardsInProgress";
      if (prev === "JobCardsInProgress") return "CompletedJobCards";
      if (prev === "CompletedJobCards") return "BreakdownList";
      return "OpenJobCards"; // Loop back to the first dataset
    });
  };

  // Navigate to the previous dataset
  const handleBack = () => {
    FetchDetails();
    clearInterval(intervalId); // Stop interval on manual navigation
    setCurrentView((prev) => {
      if (prev === "BreakdownList") return "CompletedJobCards";
      if (prev === "CompletedJobCards") return "JobCardsInProgress";
      if (prev === "JobCardsInProgress") return "OpenJobCards";
      return "BreakdownList"; // Loop back to the last dataset
    });
  };

  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  useEffect(() => {
    FetchDetails();
  }, []);
  function FetchDetails() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getopenjobcards`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data, "___OpenJobCards");
        setisloading(false);
        const dataWithIndex = res?.data.map((item, index) => ({
          ...item,
          originalIndex: index + 1, // Start index from 1
        }));
        setOpenJobCardsData(dataWithIndex);
      })
      .catch((err) => {
        console.log("Error while fetching /getopenjobcards List", err);
      });
    axios
      .get(`${BASEURL}/getopenjobcardtasks`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        // Add the original index to each item
        const dataWithIndex = res?.data.map((item, index) => ({
          ...item,
          originalIndex: index + 1, // Start index from 1
        }));
        console.log(dataWithIndex, "___JobCardsInProgress dataWithIndex");

        setJobCardsInProgressData(dataWithIndex);
      })
      .catch((err) => {
        console.log("Error while fetching /getopenjobcardtasks List", err);
      });
    axios
      .get(`${BASEURL}/gettrucksontheroad`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data, "___CompletedJobCards");
        setisloading(false);
        const dataWithIndex = res?.data.map((item, index) => ({
          ...item,
          originalIndex: index + 1, // Start index from 1
        }));
        setCompletedJobCardsData(dataWithIndex);
      })
      .catch((err) => {
        console.log("Error while fetching /gettrucksontheroad List", err);
      });
    axios
      .get(`${BASEURL}/getbreakdownlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.OT_BREAKDOWN_INCIDENT_STATUS !== "CLOSED"
        );
        const dataWithIndex = filteredData.map((item, index) => ({
          ...item,
          originalIndex: index + 1, // Start index from 1
        }));
        setisloading(false);
        setBreakdownlistData(dataWithIndex);
      })
      .catch((err) => {
        console.error("Error while fetching /getbreakdownlist:", err);
        setisloading(false);
      });
  }
  const filteredDataOpenJobCards = openJobCardsData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch = searchData.every((term) => {
      return [
        "DAYS",
        "DRIVER",
        "JOB DATE",
        "JOB ID",
        "JOB STATUS",
        "JOB TYPE",
        "TRUCK STATUS",
        "VEHICLE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  const filteredDataJobCardsInProgress = jobCardsInProgressData.filter(
    (item) => {
      const searchData = search.toLowerCase().split(" ").filter(Boolean);

      const textMatch = searchData.every((term) => {
        return [
          "ALLOCATEDTO",
          "DAYS",
          "JOB ID",
          "JOB TYPE",
          "OPERATION",
          "TASK",
          "TASK STATUS",
          "TRUCK STATUS",
          "VEHICLE",
        ].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

      return textMatch;
    }
  );

  const filteredDataCompletedJobCards = completedJobCardsData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch = searchData.every((term) => {
      return [
        "CARGO",
        "CLIENTNAME",
        "DAYS",
        "JOURNEYDATE",
        "JOURNEYNO",
        "ROUTE",
        "VEHICLE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  const filteredDataBreakdownList = BreakdownlistData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch = searchData.every((term) => {
      return [
        "VEHICLE",
        "DRIVERNAME",
        "AREA",
        "INCIDENTDATE",
        "REPORTTIME",
        "INCIDENTNO",
        "LOCATION",
        "NARRATION",
        "JOURNEYNO",
        "OT_BREAKDOWN_INCIDENT_STATUS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <AllPagesMasterNavbar />;
      <h1 className="header__of__page text-blue-950 text-center underline">
        TRUCKS STATUS{" "}
      </h1>
      <header className="header__of__main lg:flex justify-between items-end px-5">
        {currentView === "OpenJobCards" && (
          <h1 className="header__of__page text-blue-950">
            LIST OF OPEN JOB CARDS
          </h1>
        )}
        {currentView === "JobCardsInProgress" && (
          <h1 className="header__of__page text-blue-950">LIST OF OPEN TASKS</h1>
        )}
        {currentView === "CompletedJobCards" && (
          <h1 className="header__of__page text-blue-950">TRUCKS ON THE ROAD</h1>
        )}
        {currentView === "BreakdownList" && (
          <h1 className="header__of__page text-blue-950">TRUCKS BREAKDOWN</h1>
        )}
        <div className="flex gap-5">
          <button
            onClick={handleBack}
            className="bg-slate-300 p-1 rounded px-2"
          >
            <FaLessThan />
          </button>
          <button
            onClick={handleNext}
            className="bg-slate-300 p-1 rounded px-2"
          >
            <FaGreaterThan />
          </button>
        </div>
        {/* <div className="flex items-center gap-5 my-1">
          {" "}
          <div
            onClick={ExcelTechSheetData}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <RiFileExcel2Line size={35} />{" "}
          </div>
          <div
            onClick={handleGeneratePDF}
            className="border-l-indigo-900 rounded mx-2 text-blue-950 cursor-pointer"
          >
            <BsFileEarmarkPdf size={35} />
          </div>
        </div>{" "} */}
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        {/* {currentView !== "JobCardsInProgress" && (
          <div className="lg:flex items-center gap-3">
            <label className=" text-gray-700">From Date</label>
            <input
              type="date"
              onChange={(e) => {
                setStartdate(e.target.value);
              }}
              value={startdate.split("T")[0]}
              placeholder="From Date"
            />
          </div>
        )}
        {currentView !== "JobCardsInProgress" && (
          <div className="lg:flex items-center gap-3">
            <label className=" text-gray-700">End Date</label>
            <input
              type="date"
              onChange={(e) => {
                setEnddate(e.target.value);
              }}
              value={enddate.split("T")[0]}
              placeholder="To Date"
            />
          </div>
        )} */}
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <>
          {currentView === "OpenJobCards" && (
            <div className="overflow-y-auto h-[500px] px-4">
              <table className="custom-table text-sm overflow__y__ relative w-full">
                <thead
                  className="header-cell sticky top-[0px] left-[10px]"
                  style={{ zIndex: 50 }}
                >
                  <tr>
                    <td className="">SNO. </td>
                    <td className="">VEHICLE </td>{" "}
                    <td className="text-right">JOB ID</td>
                    <td className=" text-right">
                      <p>DAYS</p>
                    </td>
                    <td className="">DRIVER</td>
                    <td className="">JOB DATE</td>
                    <td className="">JOB STATUS</td>
                    <td className="">JOB TYPE </td>
                    <td className="">TRUCK STATUS </td>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataOpenJobCards.length > 0 &&
                    filteredDataOpenJobCards.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        <td className=" text-right">
                          {item.originalIndex ? item.originalIndex : "0"}
                        </td>
                        <td className=" ">
                          {item["VEHICLE"] ? item["VEHICLE"] : "NA"}
                        </td>{" "}
                        <td className=" text-right">
                          {item["JOB ID"] ? item["JOB ID"] : "NA"}
                        </td>
                        <td className=" text-right">
                          {item["DAYS"] ? item["DAYS"] : "0"}
                        </td>
                        <td className="">
                          {item["DRIVER"] ? item["DRIVER"] : "NA"}
                        </td>
                        <td className="">
                          {item["JOB DATE"]
                            ? item["JOB DATE"].slice(8, 10) +
                              "/" +
                              item["JOB DATE"].slice(5, 7) +
                              "/" +
                              item["JOB DATE"].slice(0, 4)
                            : "NA"}
                        </td>
                        <td className=" ">
                          {item["JOB STATUS"] ? item["JOB STATUS"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["JOB TYPE"] ? item["JOB TYPE"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["TRUCK STATUS"] ? item["TRUCK STATUS"] : "NA"}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="font-bold " colSpan={10}>
                      Total counter :{" "}
                      {openJobCardsData?.length ? openJobCardsData?.length : 0}
                    </td>{" "}
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </>
      )}
      {isloading ? (
        <Loading />
      ) : (
        <>
          {currentView === "JobCardsInProgress" && (
            <div className="overflow-y-auto h-[500px] px-4">
              <table className="custom-table text-sm overflow__y__ relative w-full ">
                <thead
                  className="header-cell sticky top-[0px] left-[10px]"
                  style={{ zIndex: 50 }}
                >
                  <tr>
                    <td className="">SNO. </td>
                    <td className="">VEHICLE </td>
                    <td className="">JOB ID</td>
                    <td className="">DAYS</td>
                    <td className=" ">
                      <p>ALLOCATED TO</p>
                    </td>{" "}
                    <td className="">JOB TYPE</td>
                    <td className="">OPERATION </td>
                    <td className="">TASK </td>
                    <td className="">TASK STATUS</td>
                    <td className="">TRUCK STATUS</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataJobCardsInProgress.length > 0 &&
                    filteredDataJobCardsInProgress.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        <td className=" text-right">
                          {item.originalIndex ? item.originalIndex : "0"}
                        </td>
                        <td className=" ">
                          {item["VEHICLE"] ? item["VEHICLE"] : "NA"}
                        </td>{" "}
                        <td className=" text-right">
                          {item["JOB ID"] ? item["JOB ID"] : "NA"}
                        </td>
                        <td className=" text-right">
                          {item["DAYS"] ? item["DAYS"] : "0"}
                        </td>
                        <td className=" ">
                          {item["ALLOCATEDTO"] ? item["ALLOCATEDTO"] : "NA"}
                        </td>{" "}
                        <td className=" ">
                          {item["JOB TYPE"] ? item["JOB TYPE"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["OPERATION"] ? item["OPERATION"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["TASK"] ? item["TASK"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["TASK STATUS"] ? item["TASK STATUS"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["TRUCK STATUS"] ? item["TRUCK STATUS"] : "NA"}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="font-bold " colSpan={10}>
                      Total counter :{" "}
                      {jobCardsInProgressData?.length
                        ? jobCardsInProgressData?.length
                        : 0}
                    </td>{" "}
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </>
      )}
      {isloading ? (
        <Loading />
      ) : (
        <>
          {currentView === "CompletedJobCards" && (
            <div className="overflow-y-auto h-[500px] px-4 ">
              <table className="custom-table text-sm overflow__y__ relative w-full">
                <thead
                  className="header-cell sticky top-[0px] left-[10px]"
                  style={{ zIndex: 50 }}
                >
                  <tr>
                    <td className="">SNO. </td>
                    <td className="">VEHICLE </td>{" "}
                    <td className="">JOURNEY NO </td>
                    <td className="">DAYS</td>
                    <td className="">DRIVER</td>
                    <td className="">
                      <p>CARGO</p>
                    </td>
                    <td className="">CLIENT NAME</td>
                    <td className="">JOURNEY DATE </td>
                    <td className="">ROUTE</td>
                    <td className="">IMAGE</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataCompletedJobCards.length > 0 &&
                    filteredDataCompletedJobCards.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <td className=" text-right">
                          {item.originalIndex ? item.originalIndex : "0"}
                        </td>
                        <td className=" ">
                          {item["VEHICLE"] ? item["VEHICLE"] : "NA"}
                        </td>{" "}
                        <td className="text-right ">
                          {item["JOURNEYNO"] ? item["JOURNEYNO"] : "NA"}
                        </td>{" "}
                        <td className=" text-right">
                          {item["DAYS"] ? item["DAYS"] : "0"}
                        </td>{" "}
                        <td className="">
                          {item["DRIVER"] ? item["DRIVER"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["CARGO"] ? item["CARGO"] : "NA"}
                        </td>
                        <td className="">
                          {item["CLIENTNAME"] ? item["CLIENTNAME"] : "NA"}
                        </td>
                        <td className="">
                          {item["JOURNEYDATE"]
                            ? item["JOURNEYDATE"].slice(8, 10) +
                              "/" +
                              item["JOURNEYDATE"].slice(5, 7) +
                              "/" +
                              item["JOURNEYDATE"].slice(0, 4)
                            : "NA"}
                        </td>
                        <td className=" ">
                          {item["ROUTE"] ? item["ROUTE"] : "NA"}
                        </td>
                        <td className=" ">
                          {item["IMAGEURL"] ? (
                            <img
                              src={item.IMAGEURL}
                              className="w-[70px] h-[70px] rounded-full"
                              alt=""
                            />
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="font-bold " colSpan={10}>
                      Total counter :{" "}
                      {completedJobCardsData?.length
                        ? completedJobCardsData?.length
                        : 0}
                    </td>{" "}
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </>
      )}
      {isloading ? (
        <Loading />
      ) : (
        <>
          {currentView === "BreakdownList" && (
            <div className="overflow-y-auto h-[500px] px-4 ">
              <table className="custom-table text-sm overflow__y__ relative w-full">
                <thead
                  className="header-cell sticky top-[0px] left-[10px]"
                  style={{ zIndex: 50 }}
                >
                  <tr>
                    {" "}
                    <td className="">SNO. </td>
                    <td>VEHICLE</td>
                    <td>DRIVER NAME</td>
                    <td>INCIDENT DATE</td>
                    <td>REPORT TIME</td>
                    <td className="text-right">INCIDENT NO</td>
                    <td>AREA</td>
                    <td className="location__breakdown">LOCATION</td>
                    <td>NARRATION</td>
                    <td>STATUS</td>
                    <td>JOURNEY NO</td>
                    <td>IMAGE</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataBreakdownList.length > 0 &&
                    filteredDataBreakdownList.map((data, index) => {
                      return (
                        <tr key={index}>
                          {" "}
                          <td className=" text-right">
                            {data.originalIndex ? data.originalIndex : "0"}
                          </td>
                          <td>{data.VEHICLE ? data.VEHICLE : "N/A"}</td>{" "}
                          <td>{data.DRIVERNAME ? data.DRIVERNAME : "N/A"}</td>{" "}
                          <td>
                            {data.INCIDENTDATE
                              ? data.INCIDENTDATE.substr(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("-")
                              : "N/A"}
                          </td>
                          <td>
                            {data.REPORTTIME
                              ? data.REPORTTIME.slice(11, 19)
                              : "N/A"}
                          </td>
                          <td className="text-right">
                            {data.INCIDENTNO ? data.INCIDENTNO : "N/A"}
                          </td>
                          <td>{data.AREA ? data.AREA : "N/A"}</td>
                          <td>{data.LOCATION ? data.LOCATION : "N/A"}</td>
                          <td>{data.NARRATION ? data.NARRATION : "N/A"}</td>
                          <td>
                            {data.OT_BREAKDOWN_INCIDENT_STATUS
                              ? data.OT_BREAKDOWN_INCIDENT_STATUS
                              : "N/A"}
                          </td>
                          <td>{data.JOURNEYNO ? data.JOURNEYNO : "N/A"}</td>
                          <td>
                            {data.IMAGEPATH ? (
                              <img
                                src={data.IMAGEPATH}
                                alt="BREAKDOWN IMG"
                                className="w-[70px] h-[70px] rounded-full"
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="font-bold " colSpan={10}>
                      Total breakdown counter : {BreakdownlistData?.length}
                    </td>{" "}
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TRUCKSSTATUS;
