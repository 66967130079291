import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Login";
import { useSelector } from "react-redux";
import { selectUser } from "./Components/features/userSlice";
import Footer from "./Components/Footer";
import HomePage from "./Functionality/Unapproved/HomePage";
import UnCrNo from "./Functionality/Unapproved/UnCrNo";
import UnPuOr from "./Functionality/Unapproved/UnPurchaseOrders";
import UnPtCVo from "./Functionality/Unapproved/UnPettyCashVouchers";
import UnJo from "./Functionality/Unapproved/UnJournals";
import UnPs from "./Functionality/Unapproved/UnPayments";
import ViewDetails from "./Functionality/Unapproved/ViewDetails";
import ViewPettyCashVoucher from "./Functionality/Unapproved/ViewPettyCashVoucher";
import ViewPurchaseOrder from "./Functionality/Unapproved/ViewPurchaseOrder";
import ViewJournals from "./Functionality/Unapproved/ViewJournals";
import AnalyticsBasedOnSuppliername from "./Functionality/Analytics/Trading/PurchaseAnalytics/AnalyticsBasedOnSuppliername";
import PurchasesDashboard from "./Functionality/Analytics/Trading/PurchaseAnalytics/PurchasesDashboard";
import Dashboards from "./Dashboards";
import Dashboard1 from "./Dashboard1";
import Dashboard2 from "./Dashboard2";
import TRUCKSSTATUS from "./TRUCKSSTATUS";
import TradingDashboard from "./Functionality/Unapproved/TradingDashboard";
import TransportDashboardUnapproved from "./Functionality/Unapproved/TransportDashboardUnapproved";
// Trnasport Unapproved

// Stores and Fuel
import StoreandFuelDashboard from "./Functionality/Unapproved/Transport/StoresAndFuel/StoreandFuelDashboard";
import StoreandFuelDetailsPO from "./Functionality/Unapproved/Transport/StoresAndFuel/StoreandFuelDetailsPO";
import StoreandFuelDetailsRFQ from "./Functionality/Unapproved/Transport/StoresAndFuel/StoreandFuelDetailsRFQ";
import StoreandFuelDetailsSR from "./Functionality/Unapproved/Transport/StoresAndFuel/StoreandFuelDetailsSR";

// Finance
import FinanceUnapprovedDashboard from "./Functionality/Unapproved/Transport/Finance/FinanceUnapprovedDashboard";
import FinanceDetailsPayments from "./Functionality/Unapproved/Transport/Finance/FinanceDetailsPayments";
import FinanceDetailsCreditNotes from "./Functionality/Unapproved/Transport/Finance/FinanceDetailsCreditNotes";
import FinanceDetailsJournals from "./Functionality/Unapproved/Transport/Finance/FinanceDetailsJournals";

// Transport Operations
import TransportOperationDashboard from "./Functionality/Unapproved/Transport/TransportOperations/TransportOperationsDashboard";
import TransportOperationsDetailsLO from "./Functionality/Unapproved/Transport/TransportOperations/TransportOperationsDetailsLO";
import TransportOperationsDetailsFV from "./Functionality/Unapproved/Transport/TransportOperations/TransportOperationsDetailsFV";
import TransportOperationsDetailsMV from "./Functionality/Unapproved/Transport/TransportOperations/TransportOperationsDetailsMV";

// Workshop
import WorkshopDashboard from "./Functionality/Unapproved/Transport/Workshop/WorkshopDashboard";
import WorkshopDetailsESI from "./Functionality/Unapproved/Transport/Workshop/WorkshopDetailsESI";
import WorkshopDetailsPR from "./Functionality/Unapproved/Transport/Workshop/WorkshopDetailsPR";

import Forgotpwd from "./Forgotpwd";
import AnalyticsDashboard from "./AnalyticsDashboard";
import AnalyticsBasedOnDivission from "./Functionality/Analytics/Trading/PurchaseAnalytics/AnalyticsBasedOnDivission";
import AnalyticsBasedOnPurechaseType from "./Functionality/Analytics/Trading/PurchaseAnalytics/AnalyticsBasedOnPurechaseType";
import AnalyticsBasedOnCategory from "./Functionality/Analytics/Trading/PurchaseAnalytics/AnalyticsBasedOnCategory";
import AnalyticsBasedOnSubCategory from "./Functionality/Analytics/Trading/PurchaseAnalytics/AnalyticsBasedOnSubCategory";
import SalesDashboard from "./Functionality/Analytics/Trading/SalesAnalytics/SalesDashboard";
import AnalyticsBasedonClientName from "./Functionality/Analytics/Trading/SalesAnalytics/AnalyticsBasedonClientName";
import AnalyticsBasedOnItem from "./Functionality/Analytics/Trading/SalesAnalytics/AnalyticsBasedOnItem";
import AnalyticsBasedOnQuantity from "./Functionality/Analytics/Trading/SalesAnalytics/AnalyticsBasedOnQuantity";
import AnalyticsBasedOnClientAndCategory from "./Functionality/Analytics/Trading/SalesAnalytics/AnalyticsBasedOnClientAndCategory";
import AnalyticsBasedOnClientAndItem from "./Functionality/Analytics/Trading/SalesAnalytics/AnalyticsBasedOnClientAndItem";
import AnalyticsBasedOnAllCombinations from "./Functionality/Analytics/Trading/SalesAnalytics/AnalyticsBasedOnAllCombinations";
import AnalyticsBasedOnQuantityandDate from "./Functionality/Analytics/Trading/StockAnalytics/AnalyticsBasedOnQuantityandDate";
import StockDashboard from "./Functionality/Analytics/Trading/StockAnalytics/StockDashboard";
import AnalyticsBasedOnSubCategoryandQuantity from "./Functionality/Analytics/Trading/StockAnalytics/AnalyticsBasedOnSubCategoryandQuantity";
import AnalyticsBasedOnLocationWiseQuantitySold from "./Functionality/Analytics/Trading/StockAnalytics/AnalyticsBasedOnLocationWiseQuantitySold";
import AnalyticsBasedOnStockMovement from "./Functionality/Analytics/Trading/StockAnalytics/AnalyticsBasedOnStockMovement";
import ItemSelectionWindow from "./Functionality/Analytics/Trading/StockAnalytics/ItemSelectionWindow";
import FinancialDashboard from "./Functionality/Analytics/Trading/Financial Analytics/FinancialDashboard";
import AnalyticsBasedOnTrialBalance from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnTrialBalance";
import AnalyticsBasedOnNominalActivity from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnNominalActivity";
import AnalyticsBasedOnProfitandLoss from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnProfitandLoss";
import AnalyticsBasedOnBalanceSheet from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnBalanceSheet";
import AnalyticsBasedOnCustomerStatement from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnCustomerStatement";
import AnalyticsBasedOnSupplierStatement from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnSupplierStatement";
import AnalyticsBasedOnOutstandingDebtors from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnOutstandingDebtors";
import AnalyticsBasedOnCreditorsOutstanding from "./Functionality/Analytics/Trading/Financial Analytics/AnalyticsBasedOnCreditorsOutstanding";
import TransportDashboard from "./Functionality/Analytics/Transport/TransportDashboard";
import Workshop from "./Functionality/Analytics/Transport/Workshop";
import BreakDowns from "./Functionality/Analytics/Transport/BreakDowns";
import VehicleExpense from "./Functionality/Analytics/Transport/VehicleExpense";
import TransportDashboard1 from "./Functionality/Analytics/Transport/TransportDashboard1";
import FuelExpense from "./Functionality/Analytics/Transport/FuelExpense";
import TripExpense from "./Functionality/Analytics/Transport/TripExpense";

// WORKSHOP ANALYTICS
import BreakdownAnalysis from "./Functionality/Analytics/Transport/WorkshopAnalytics/BreakdownAnalysis";
import TotalWorkshopExpenses from "./Functionality/Analytics/Transport/WorkshopAnalytics/TotalWorkshopExpenses";
import VehicleJobs from "./Functionality/Analytics/Transport/WorkshopAnalytics/VehicleJobs";
import VehicleMakewiseExpenses from "./Functionality/Analytics/Transport/WorkshopAnalytics/VehicleMakewiseExpenses";
import VehicleWiseExpenses from "./Functionality/Analytics/Transport/WorkshopAnalytics/VehicleWiseExpenses";
//FUEL
import FuelPurchaseSelectedSupplier from "./Functionality/Analytics/Transport/ProcurementFuelAnalytics/FuelPurchaseSelectedSupplier";
import FuelPurchasesMonthly from "./Functionality/Analytics/Transport/ProcurementFuelAnalytics/FuelPurchasesMonthly";
import FuelConsumptionperiodic from "./Functionality/Analytics/Transport/ConsumptionFuelAnalytics/FuelConsumptionperiodic";
import FuelPurchaseSupplierWise from "./Functionality/Analytics/Transport/ProcurementFuelAnalytics/FuelPurchaseSupplierEise";
import FuelConsumptiondateandvahiclewise from "./Functionality/Analytics/Transport/ConsumptionFuelAnalytics/FuelConsumptionvehiclewise";
import FuelConsumptionClientWise from "./Functionality/Analytics/Transport/ConsumptionFuelAnalytics/FuelConsumptionClientWise";

//Inventory
import InventoryAnalytics from "./Functionality/Analytics/Transport/InventoryAnalytics";
import PurchasePeriodWise from "./Functionality/Analytics/Transport/Inventory/PurchasesPeriodWide";
import PurchasesPurchaseTypewise from "./Functionality/Analytics/Transport/Inventory/PurchasesPurchaseTypewise";
// TransportAnalyticsMAIN
import TransportAnalyticsMAIN from "./Functionality/Analytics/Transport/TransportAnalytics/TransportAnalyticsMAIN";
// ProductionAnalytics
import ProductionCost from "./Functionality/Analytics/Production/ProductionCost/ProductionCost";
import Production from "./Functionality/Analytics/Production/Production";
//cnf
import CNF from "./Functionality/Analytics/CNF/CNF";
import IncomeAndExpenses from "./Functionality/Analytics/CNF/IncomeAndExpenses/IncomeAndExpenses";
import ProductionQuantities from "./Functionality/Analytics/Production/ProductionQuantities/ProductionQuantities";
//TransportExpenses
import TransportExpenses from "./Functionality/Analytics/Transport/TransportExpenses/TransportExpenses";
//TransportIncome
import TransportIncome from "./Functionality/Analytics/Transport/TransportIncome/TransportIncome";
import AnalyticsDashBoardCNF from "./Functionality/Analytics/CNF/CNFAnalyticsDashBoard/AnalyticsDashBoardCNF";
import DetailedDashboardView from "./Functionality/Unapproved/DetailedDashboardView";

import CaptureEmployee from "./CaptureEmployee";
const App = () => {
  const IsloggedIn = useSelector(selectUser);
  const Protectedroute = ({ children }) => {
    return IsloggedIn.loggedIn ? children : <Navigate to="/" />;
  };
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Forgotpwd" element={<Forgotpwd />} />
          <Route
            path="/CaptureEmployee"
            element={
              <Protectedroute>
                <CaptureEmployee />
              </Protectedroute>
            }
          />
          <Route
            path="/DetailedDashboardView"
            element={
              <Protectedroute>
                <DetailedDashboardView />
              </Protectedroute>
            }
          />
          <Route
            path="/TRUCKSDASHBOARDS"
            element={
              <Protectedroute>
                <TRUCKSSTATUS />
              </Protectedroute>
            }
          />
          <Route
            path="/Dashboards"
            element={
              <Protectedroute>
                <Dashboards />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsDashboard"
            element={
              <Protectedroute>
                <AnalyticsDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/Dashboard1"
            element={
              <Protectedroute>
                <Dashboard1 />
              </Protectedroute>
            }
          />
          <Route
            path="/APPROVALDASHBOARDS"
            element={
              <Protectedroute>
                <Dashboard2 />
              </Protectedroute>
            }
          />
          <Route
            path="/TradingDashboard"
            element={
              <Protectedroute>
                <TradingDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportDashboardUnapproved"
            element={
              <Protectedroute>
                <TransportDashboardUnapproved />
              </Protectedroute>
            }
          />
          {/* Transport Unapproved */}
          {/* Stores and Fuel */}
          <Route
            path="/StoreandFuelDashboard"
            element={
              <Protectedroute>
                <StoreandFuelDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/StoreandFuelDetailsPO"
            element={
              <Protectedroute>
                <StoreandFuelDetailsPO />
              </Protectedroute>
            }
          />
          <Route
            path="/StoreandFuelDetailsRFQ"
            element={
              <Protectedroute>
                <StoreandFuelDetailsRFQ />
              </Protectedroute>
            }
          />
          <Route
            path="/StoreandFuelDetailsSR"
            element={
              <Protectedroute>
                <StoreandFuelDetailsSR />
              </Protectedroute>
            }
          />
          {/* Finance  */}
          <Route
            path="/FinanceUnapprovedDashboard"
            element={
              <Protectedroute>
                <FinanceUnapprovedDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/FinanceDetailsPayments"
            element={
              <Protectedroute>
                <FinanceDetailsPayments />
              </Protectedroute>
            }
          />
          <Route
            path="/FinanceDetailsCreditNotes"
            element={
              <Protectedroute>
                <FinanceDetailsCreditNotes />
              </Protectedroute>
            }
          />
          <Route
            path="/FinanceDetailsJournals"
            element={
              <Protectedroute>
                <FinanceDetailsJournals />
              </Protectedroute>
            }
          />
          {/* Transport Operation */}
          <Route
            path="/TransportOperationDashboard"
            element={
              <Protectedroute>
                <TransportOperationDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportOperationsDetailsLO"
            element={
              <Protectedroute>
                <TransportOperationsDetailsLO />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportOperationsDetailsFV"
            element={
              <Protectedroute>
                <TransportOperationsDetailsFV />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportOperationsDetailsMV"
            element={
              <Protectedroute>
                <TransportOperationsDetailsMV />
              </Protectedroute>
            }
          />
          {/* Workshop */}
          <Route
            path="/WorkshopDashboard"
            element={
              <Protectedroute>
                <WorkshopDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/WorkshopDetailsESI"
            element={
              <Protectedroute>
                <WorkshopDetailsESI />
              </Protectedroute>
            }
          />
          <Route
            path="/WorkshopDetailsPR"
            element={
              <Protectedroute>
                <WorkshopDetailsPR />
              </Protectedroute>
            }
          />
          <Route
            path="/HomePage"
            element={
              <Protectedroute>
                <HomePage />
              </Protectedroute>
            }
          />
          <Route
            path="/UnapprovedCreditNotes"
            element={
              <Protectedroute>
                {" "}
                <UnCrNo />
              </Protectedroute>
            }
          />
          <Route
            path="/UnapprovedPurchaseOrders"
            element={
              <Protectedroute>
                <UnPuOr />
              </Protectedroute>
            }
          />
          <Route
            path="/UnapprovedPettyCashVouchers"
            element={
              <Protectedroute>
                <UnPtCVo />
              </Protectedroute>
            }
          />
          <Route
            path="/UnapprovedJournals"
            element={
              <Protectedroute>
                <UnJo />
              </Protectedroute>
            }
          />
          <Route
            path="/UnapprovedPayments"
            element={
              <Protectedroute>
                <UnPs />
              </Protectedroute>
            }
          />
          <Route
            path="/viewdetails"
            element={
              <Protectedroute>
                <ViewDetails />
              </Protectedroute>
            }
          />
          <Route
            path="/ViewPettyCashVouchers"
            element={
              <Protectedroute>
                <ViewPettyCashVoucher />
              </Protectedroute>
            }
          />
          <Route
            path="/ViewPurchaseOrder"
            element={
              <Protectedroute>
                <ViewPurchaseOrder />
              </Protectedroute>
            }
          />
          <Route
            path="/PurchasesDashboard"
            element={
              <Protectedroute>
                <PurchasesDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/SalesDashboard"
            element={
              <Protectedroute>
                <SalesDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnSuppliername"
            element={
              <Protectedroute>
                <AnalyticsBasedOnSuppliername />
              </Protectedroute>
            }
          />
          <Route
            path="/ViewJournals"
            element={
              <Protectedroute>
                <ViewJournals />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnDivission"
            element={
              <Protectedroute>
                <AnalyticsBasedOnDivission />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnPurechaseType"
            element={
              <Protectedroute>
                <AnalyticsBasedOnPurechaseType />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnCategory"
            element={
              <Protectedroute>
                <AnalyticsBasedOnCategory />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnSubCategory"
            element={
              <Protectedroute>
                <AnalyticsBasedOnSubCategory />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedonClientName"
            element={
              <Protectedroute>
                <AnalyticsBasedonClientName />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnItem"
            element={
              <Protectedroute>
                <AnalyticsBasedOnItem />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnQuantity"
            element={
              <Protectedroute>
                <AnalyticsBasedOnQuantity />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnClientAndCategory"
            element={
              <Protectedroute>
                <AnalyticsBasedOnClientAndCategory />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnClientAndItem"
            element={
              <Protectedroute>
                <AnalyticsBasedOnClientAndItem />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnAllCombinations"
            element={
              <Protectedroute>
                <AnalyticsBasedOnAllCombinations />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnQuantityandDate"
            element={
              <Protectedroute>
                <AnalyticsBasedOnQuantityandDate />
              </Protectedroute>
            }
          />
          <Route
            path="/StockDashboard"
            element={
              <Protectedroute>
                <StockDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnSubCategoryandQuantity"
            element={
              <Protectedroute>
                <AnalyticsBasedOnSubCategoryandQuantity />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnLocationWiseQuantitySold"
            element={
              <Protectedroute>
                <AnalyticsBasedOnLocationWiseQuantitySold />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnStockMovement"
            element={
              <Protectedroute>
                <AnalyticsBasedOnStockMovement />
              </Protectedroute>
            }
          />
          <Route
            path="/ItemSelectionWindow"
            element={
              <Protectedroute>
                <ItemSelectionWindow />
              </Protectedroute>
            }
          />
          <Route
            path="/FinancialDashboard"
            element={
              <Protectedroute>
                <FinancialDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnTrialBalance"
            element={
              <Protectedroute>
                <AnalyticsBasedOnTrialBalance />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnNominalActivity"
            element={
              <Protectedroute>
                <AnalyticsBasedOnNominalActivity />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnProfitandLoss"
            element={
              <Protectedroute>
                <AnalyticsBasedOnProfitandLoss />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnBalanceSheet"
            element={
              <Protectedroute>
                <AnalyticsBasedOnBalanceSheet />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnCustomerStatement"
            element={
              <Protectedroute>
                <AnalyticsBasedOnCustomerStatement />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnSupplierStatement"
            element={
              <Protectedroute>
                <AnalyticsBasedOnSupplierStatement />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnOutstandingDebtors"
            element={
              <Protectedroute>
                <AnalyticsBasedOnOutstandingDebtors />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsBasedOnCreditorsOutstanding"
            element={
              <Protectedroute>
                <AnalyticsBasedOnCreditorsOutstanding />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportDashboard"
            element={
              <Protectedroute>
                <TransportDashboard />
              </Protectedroute>
            }
          />
          <Route
            path="/Workshop"
            element={
              <Protectedroute>
                <Workshop />
              </Protectedroute>
            }
          />
          <Route
            path="/BreakDowns"
            element={
              <Protectedroute>
                <BreakDowns />
              </Protectedroute>
            }
          />
          <Route
            path="/VehicleExpense"
            element={
              <Protectedroute>
                <VehicleExpense />
              </Protectedroute>
            }
          />
          <Route
            path="/TRANSPORTDASHBOARDS"
            element={
              <Protectedroute>
                <TransportDashboard1 />
              </Protectedroute>
            }
          />
          <Route
            path="/FuelExpense"
            element={
              <Protectedroute>
                <FuelExpense />
              </Protectedroute>
            }
          />
          <Route
            path="/TripExpense"
            element={
              <Protectedroute>
                <TripExpense />
              </Protectedroute>
            }
          />
          {/* workshop */}
          <Route
            path="/BreakdownAnalysis"
            element={
              <Protectedroute>
                <BreakdownAnalysis />
              </Protectedroute>
            }
          />
          <Route
            path="/TotalWorkshopExpenses"
            element={
              <Protectedroute>
                <TotalWorkshopExpenses />
              </Protectedroute>
            }
          />
          <Route
            path="/VehicleJobs"
            element={
              <Protectedroute>
                <VehicleJobs />
              </Protectedroute>
            }
          />
          <Route
            path="/VehicleMakewiseExpenses"
            element={
              <Protectedroute>
                <VehicleMakewiseExpenses />
              </Protectedroute>
            }
          />
          <Route
            path="/VehicleWiseExpenses"
            element={
              <Protectedroute>
                <VehicleWiseExpenses />
              </Protectedroute>
            }
          />
          {/* FUEL */}
          <Route
            path="/FuelPurchaseSelectedSupplier"
            element={
              <Protectedroute>
                <FuelPurchaseSelectedSupplier />
              </Protectedroute>
            }
          />
          <Route
            path="/FuelPurchasesMonthly"
            element={
              <Protectedroute>
                <FuelPurchasesMonthly />
              </Protectedroute>
            }
          />
          <Route
            path="/FuelConsumptionperiodic"
            element={
              <Protectedroute>
                <FuelConsumptionperiodic />
              </Protectedroute>
            }
          />
          <Route
            path="/FuelPurchaseSupplierWise"
            element={
              <Protectedroute>
                <FuelPurchaseSupplierWise />
              </Protectedroute>
            }
          />
          <Route
            path="/FuelConsumptiondateandvahiclewise"
            element={
              <Protectedroute>
                <FuelConsumptiondateandvahiclewise />
              </Protectedroute>
            }
          />
          <Route
            path="/FuelConsumptionClientWise"
            element={
              <Protectedroute>
                <FuelConsumptionClientWise />
              </Protectedroute>
            }
          />
          <Route
            path="/PurchasePeriodWise"
            element={
              <Protectedroute>
                <PurchasePeriodWise />
              </Protectedroute>
            }
          />
          <Route
            path="/PurchasesPurchaseTypewise"
            element={
              <Protectedroute>
                <PurchasesPurchaseTypewise />
              </Protectedroute>
            }
          />
          <Route
            path="/InventoryAnalytics"
            element={
              <Protectedroute>
                <InventoryAnalytics />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportAnalyticsMAIN"
            element={
              <Protectedroute>
                <TransportAnalyticsMAIN />
              </Protectedroute>
            }
          />
          <Route
            path="/ProductionCost"
            element={
              <Protectedroute>
                <ProductionCost />
              </Protectedroute>
            }
          />
          <Route
            path="/Production"
            element={
              <Protectedroute>
                <Production />
              </Protectedroute>
            }
          />
          <Route
            path="/ProductionQuantities"
            element={
              <Protectedroute>
                <ProductionQuantities />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportExpenses"
            element={
              <Protectedroute>
                <TransportExpenses />
              </Protectedroute>
            }
          />
          <Route
            path="/TransportIncome"
            element={
              <Protectedroute>
                <TransportIncome />
              </Protectedroute>
            }
          />{" "}
          {/* cnf */}
          <Route
            path="/CLEARINGANDFORWARDINGDASHBOARDS"
            element={
              <Protectedroute>
                <CNF />
              </Protectedroute>
            }
          />
          <Route
            path="/IncomeAndExpenses"
            element={
              <Protectedroute>
                <IncomeAndExpenses />
              </Protectedroute>
            }
          />
          <Route
            path="/AnalyticsDashBoardCNF"
            element={
              <Protectedroute>
                <AnalyticsDashBoardCNF />
              </Protectedroute>
            }
          />
        </Routes>
      </BrowserRouter>
      <div className="mt-[40vh]">
        <Footer />
      </div>
    </div>
  );
};

export default App;
