import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";

const AnalyticsBasedOnAllCombinations = () => {
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [item, setItem] = useState([]);
  const [client, setClient] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  var [selclientcode, setSelClientcode] = useState("");
  var [selclientname, setSelClientName] = useState("");
  var [selItemCode, setSelItemCode] = useState("");
  var [selItemName, setSelItemName] = useState("");
  var [selcategory, setSelCategory] = useState("");
  var [selsubcategory, setSelsubcategory] = useState("");
  const [data, setData] = useState([]);
  const [datawithitem, setDataWithItem] = useState([]);
  const [datawithclient, setDataWithClient] = useState([]);
  const [datawithcategory, setDataWithCategory] = useState([]);
  const [datawithsubcategory, setDataWithSubcategory] = useState([]);
  const [datawithitemclient, setDataWithItemClient] = useState([]);
  const [datawithitemcategory, setDataWithItemCategory] = useState([]);
  const [datawithitemsubcategory, setDataWithItemSubcategory] = useState([]);
  const [datawithclientcategory, setDataWithClientCategory] = useState([]);
  const [datawithclientsubcategory, setDataWithClientSubcategory] = useState(
    []
  );
  const [datawithcategorysubcategory, setDataWithCategorySubcategory] =
    useState([]);
  const [datawithitemclientcategory, setDataWithItemClientCategory] = useState(
    []
  );
  const [datawithitemclientsubcategory, setDataWithItemClientSubcategory] =
    useState([]);
  const [datawithitemcategorysubcategory, setDataWithItemCategorySubcategory] =
    useState([]);
  const [
    datawithclientcategorysubcategory,
    setDataWithClientCategorySubcategory,
  ] = useState([]);
  const [
    datawithitemclientcategorysubcategory,
    setDataWithItemClientCategorySubcategory,
  ] = useState([]);

  useEffect(() => {
    AnalyticsBasedOnAllCombinations();
    getProduct();
    getClient();
    getCategory();
    getSubcategory();

    setisloading(false);
  }, [
    selItemCode,
    selItemName,
    selclientcode,
    selclientname,
    selcategory,
    selsubcategory,
    fromdate,
    todate,
  ]);

  const getProduct = () => {
    axios
      .get(`${BASEURL}/Item`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setItem(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };

  const getClient = () => {
    axios
      .get(`${BASEURL}/Client`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setClient(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };

  const getCategory = () => {
    axios
      .get(`${BASEURL}/Category`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Category:", error);
      });
  };

  const getSubcategory = () => {
    axios
      .get(`${BASEURL}/SubCategory`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setSubcategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Sub-Category:", error);
      });
  };

  const handleClientCode = (e) => {
    const selectedValue = e;
    const selectedOption = client.find(
      (obj) => obj.CLIENTCODE === selectedValue
    );
    if (selectedValue === "") {
      setSelClientName("");
      setSelClientcode("");
      setDataWithClient([]);
    } else if (selectedOption === "") {
      setSelClientName("");
      setSelClientcode("");
      setDataWithClient([]);
    } else {
      setSelClientcode(selectedValue);
      setSelClientName(selectedOption.CLIENTNAME);
    }
  };

  const handleClientName = (e) => {
    const selectedValue = e;
    const selectedOption = client.find(
      (obj) => obj.CLIENTNAME === selectedValue
    );
    if (selectedValue === "") {
      setSelClientName("");
      setSelClientcode("");
      setDataWithClient([]);
    } else if (selectedOption === "") {
      setSelClientcode("");
      setSelClientName("");
      setDataWithClient([]);
    } else {
      setSelClientName(selectedValue);
      setSelClientcode(selectedOption.CLIENTCODE);
    }
  };

  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = item.find(
      (obj) => obj.OM_ITEM_DESCRIPTION === selectedValue
    );
    if (selectedValue === "") {
      setSelItemName("");
      setSelItemCode("");
      setDataWithItem([]);
    } else if (selectedOption === "") {
      setSelItemCode("");
      setSelItemName("");
      setDataWithItem([]);
    } else {
      setSelItemName(selectedValue);
      setSelItemCode(selectedOption.OM_ITEM_CODE);
    }
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = item.find(
      (obj) => obj.OM_ITEM_CODE === selectedValue
    );
    if (selectedValue === "") {
      setSelItemName("");
      setSelItemCode("");
      setDataWithItem([]);
    } else if (selectedOption === "") {
      setSelItemName("");
      setSelItemCode("");
      setDataWithItem([]);
    } else {
      setSelItemCode(selectedValue);
      setSelItemName(selectedOption.OM_ITEM_DESCRIPTION);
    }
  };

  const AnalyticsBasedOnAllCombinations = () => {
    if (
      selItemCode === "" &&
      selclientcode === "" &&
      selcategory === "" &&
      selsubcategory === ""
    ) {
      selItemCode = "null";
      selclientcode = "null";
      selcategory = "null";
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode === "" &&
      selcategory === "" &&
      selsubcategory === ""
    ) {
      selclientcode = "null";
      selcategory = "null";
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItem(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode !== "" &&
      selcategory === "" &&
      selsubcategory === ""
    ) {
      selItemCode = "null";
      selcategory = "null";
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithClient(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode === "" &&
      selcategory !== "" &&
      selsubcategory === ""
    ) {
      selItemCode = "null";
      selclientcode = "null";
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithCategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode === "" &&
      selcategory === "" &&
      selsubcategory !== ""
    ) {
      selItemCode = "null";
      selclientcode = "null";
      selcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithSubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode !== "" &&
      selcategory === "" &&
      selsubcategory === ""
    ) {
      selsubcategory = "null";
      selcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemClient(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode === "" &&
      selcategory !== "" &&
      selsubcategory === ""
    ) {
      selsubcategory = "null";
      selclientcode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemCategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode === "" &&
      selcategory === "" &&
      selsubcategory !== ""
    ) {
      selcategory = "null";
      selclientcode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemSubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode !== "" &&
      selcategory !== "" &&
      selsubcategory === ""
    ) {
      selItemCode = "null";
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithClientCategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode !== "" &&
      selcategory === "" &&
      selsubcategory !== ""
    ) {
      selItemCode = "null";
      selcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithClientSubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode === "" &&
      selcategory !== "" &&
      selsubcategory !== ""
    ) {
      selItemCode = "null";
      selclientcode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithCategorySubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode !== "" &&
      selcategory !== "" &&
      selsubcategory === ""
    ) {
      selsubcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemClientCategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode !== "" &&
      selcategory === "" &&
      selsubcategory !== ""
    ) {
      selcategory = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemClientSubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode === "" &&
      selcategory !== "" &&
      selsubcategory !== ""
    ) {
      selclientcode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemCategorySubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode === "" &&
      selclientcode !== "" &&
      selcategory !== "" &&
      selsubcategory !== ""
    ) {
      selItemCode = "null";
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithClientCategorySubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    } else if (
      selItemCode !== "" &&
      selclientcode !== "" &&
      selcategory !== "" &&
      selsubcategory !== ""
    ) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/AnalyticsBasedOnAllCombinations/${selItemCode}/${selclientcode}/${selcategory}/${selsubcategory}`,
          {
            Fromdate: moment(fromdate).format("DD/MM/YYYY"),
            Todate: moment(todate).format("DD/MM/YYYY"),
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setDataWithItemClientCategorySubcategory(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {/* <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/SALESANALYSIS"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div> */}
      {/*  */}{" "}
      <div className="align__date">
        <div className="">
          <div>
            <p>Start Date :</p>
          </div>
          <div>
            <input
              value={fromdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid fromdate");
                } else {
                  setFromdate(e.target.value);
                }
              }}
            />
          </div>

          <div>
            <p>End Date :</p>
          </div>
          <div>
            <input
              value={todate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid todate");
                } else {
                  setTodate(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="Supp_analytics_Supp_Name_2">
          <div className="analytics_product_select">
            <aside>
              <p>Client Code :</p>
            </aside>
            <Select
              className="Select_item_code"
              onChange={(selectedOption) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  handleClientCode(selectedOption.value);
                }
              }}
              value={
                selclientcode
                  ? { label: selclientcode, value: selclientcode }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...client.map((item) => ({
                  value: item.CLIENTCODE,
                  label: item.CLIENTCODE,
                })),
              ]}
            />
          </div>

          <div className="analytics_product_select">
            <aside>
              <p>Client Name :</p>
            </aside>
            <Select
              className="Select_item_name"
              onChange={(selectedOption) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  handleClientName(selectedOption.value);
                }
              }}
              value={
                selclientname
                  ? { label: selclientname, value: selclientname }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...client.map((item) => ({
                  value: item.CLIENTNAME,
                  label: item.CLIENTNAME,
                })),
              ]}
            />
          </div>
        </div>
      </div>
      <div className="Supp_analytics_Supp_Name_1" style={{ gap: "140px" }}>
        <div className="analytics_Category_2_select">
          <aside>
            <p>Item Code :</p>
          </aside>
          <Select
            className="Select_item_code_1"
            onChange={(selectedOption) => {
              if (data.length <= 0) {
                alert("There is no data to sort!");
              } else {
                handleItemCode(selectedOption.value);
              }
            }}
            value={
              selItemCode
                ? { label: selItemCode, value: selItemCode }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...item.map((item) => ({
                value: item.OM_ITEM_CODE,
                label: item.OM_ITEM_CODE,
              })),
            ]}
          />
        </div>
        <div className="analytics_Category_2_select">
          <aside>
            <p>Item Name :</p>
          </aside>
          <Select
            className="Select_item_name_1"
            onChange={(selectedOption) => {
              if (data.length <= 0) {
                alert("There is no data to sort!");
              } else {
                handleItemName(selectedOption.value);
              }
            }}
            value={
              selItemName
                ? { label: selItemName, value: selItemName }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...item.map((item) => ({
                value: item.OM_ITEM_DESCRIPTION,
                label: item.OM_ITEM_DESCRIPTION,
              })),
            ]}
          />
        </div>
      </div>
      <main>
        <div className="Supp_analytics_Supp_Name_1">
          <div className="analytics_Category_2_select">
            <aside>
              <p>Category :</p>
            </aside>
            <Select
              className="Select_Category"
              onChange={(e) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  setSelCategory(e.value);
                  if (e.value === "") {
                    setDataWithCategory([]);
                    setDataWithItemCategory([]);
                    setDataWithClientCategory([]);
                    setDataWithCategorySubcategory([]);
                    setDataWithItemClientCategory([]);
                    setDataWithItemCategorySubcategory([]);
                    setDataWithClientCategorySubcategory([]);
                    setDataWithItemClientCategorySubcategory([]);
                  }
                }
              }}
              value={
                selcategory
                  ? { label: selcategory, value: selcategory }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...category.map((item) => ({
                  value: item.CATEGORY,
                  label: item.CATEGORY,
                })),
              ]}
            />
          </div>

          <div className="analytics_Category_2_select">
            <aside>
              <p>Sub-Category :</p>
            </aside>
            <Select
              className="Select_Category"
              onChange={(e) => {
                if (data.length <= 0) {
                  alert("There is no data to sort!");
                } else {
                  setSelsubcategory(e.value);
                  if (e.value === "") {
                    setDataWithSubcategory([]);
                    setDataWithItemSubcategory([]);
                    setDataWithClientSubcategory([]);
                    setDataWithCategorySubcategory([]);
                    setDataWithItemClientSubcategory([]);
                    setDataWithItemCategorySubcategory([]);
                    setDataWithClientCategorySubcategory([]);
                    setDataWithItemClientCategorySubcategory([]);
                  }
                }
              }}
              value={
                selsubcategory
                  ? { label: selsubcategory, value: selsubcategory }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...subcategory.map((item) => ({
                  value: item.CATEGORY,
                  label: item.CATEGORY,
                })),
              ]}
            />
          </div>
        </div>
      </main>
      <div style={{ width: "80%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {selItemCode &&
            !selclientcode &&
            !selcategory &&
            !selsubcategory &&
            datawithitem.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitem.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitem.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              selclientcode &&
              !selcategory &&
              !selsubcategory &&
              datawithclient.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithclient.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithclient.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              !selclientcode &&
              selcategory &&
              !selsubcategory &&
              datawithcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              !selclientcode &&
              !selcategory &&
              selsubcategory &&
              datawithsubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithsubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithsubcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              selclientcode &&
              !selcategory &&
              !selsubcategory &&
              datawithitemclient.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemclient.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemclient.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              !selclientcode &&
              selcategory &&
              !selsubcategory &&
              datawithitemcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              !selclientcode &&
              !selcategory &&
              selsubcategory &&
              datawithitemsubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemsubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemsubcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              selclientcode &&
              selcategory &&
              !selsubcategory &&
              datawithclientcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithclientcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithclientcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              selclientcode &&
              !selcategory &&
              selsubcategory &&
              datawithclientsubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithclientsubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithclientsubcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              !selclientcode &&
              selcategory &&
              selsubcategory &&
              datawithcategorysubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithcategorysubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithcategorysubcategory.map(
                      (item) => item.AMOUNT
                    ),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              selclientcode &&
              selcategory &&
              !selsubcategory &&
              datawithitemclientcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemclientcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemclientcategory.map((item) => item.AMOUNT),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              selclientcode &&
              !selcategory &&
              selsubcategory &&
              datawithitemclientsubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemclientsubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemclientsubcategory.map(
                      (item) => item.AMOUNT
                    ),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              !selclientcode &&
              selcategory &&
              selsubcategory &&
              datawithitemcategorysubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemcategorysubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemcategorysubcategory.map(
                      (item) => item.AMOUNT
                    ),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              selclientcode &&
              selcategory &&
              selsubcategory &&
              datawithclientcategorysubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithclientcategorysubcategory.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithclientcategorysubcategory.map(
                      (item) => item.AMOUNT
                    ),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : selItemCode &&
              selclientcode &&
              selcategory &&
              selsubcategory &&
              datawithitemclientcategorysubcategory.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: datawithitemclientcategorysubcategory.map(
                      (item) => moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Amount", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: datawithitemclientcategorysubcategory.map(
                      (item) => item.AMOUNT
                    ),
                  },
                ]}
                type="line"
                height={300}
              />
            ) : !selItemCode &&
              !selclientcode &&
              !selcategory &&
              !selsubcategory &&
              data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) => item["CLIENT NAME"]),
                    title: {
                      text: "Client Name", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Revenue", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Revenue",
                    data: data.map((item) => item.AMOUNT),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}{" "}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default AnalyticsBasedOnAllCombinations;
