import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../Components/LoadingScreen";
import Loading2 from "../../../Components/Loading2";

const TripExpense = () => {
  const today = new Date(); // Get the current date
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 2); // Get the first day of the current month
  const [startdate, setStartdate] = useState(startOfMonth.toISOString());
  const [enddate, setEnddate] = useState(new Date().toISOString());
  const [deliverytripno, setDeliveryTripNo] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [driverName, setDriverName] = useState([]);

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isLoading2, setLoading2] = useState(true);
  const [isloading, setisloading] = useState(true);
  const [routestartpoint, setRouteStartPoint] = useState([]);
  const [routeendpoint, setRouteEndPoint] = useState([]);
  const [contractno, setContractNo] = useState([]);
  const [data, setData] = useState([]);

  var seldeliverytripno = "";
  var selclientname = "";
  var seldrivername = "";
  var selRouteStart = "";
  var selRouteEnd = "";
  var selContractNo = "";

  useEffect(() => {
    DeliveryTripNo();
    ClientName();
    Driver();
    routeStart();
    routeend();
    Contract();
    costoftripexpenses();
  }, [startdate, enddate]);

  const DeliveryTripNo = async () => {
    setisloading(true);
    try {
      const delivery = await axios.post(
        `${BASEURL}/DeliveryTripNo`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setDeliveryTripNo(delivery.data);
      setisloading(false);
    } catch (error) {
      console.log("Error Fetching Driver Details", error);
      setisloading(false);
    }
  };

  const ClientName = async () => {
    setisloading(true);
    try {
      const client = await axios.post(
        `${BASEURL}/ClientName`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setClientName(client.data);
      setisloading(false);
    } catch (error) {
      console.log("Error Fetching Driver Details", error);
      setisloading(false);
    }
  };
  const Driver = async () => {
    setisloading(true);
    try {
      const driver = await axios.post(
        `${BASEURL}/Driverr`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setDriverName(driver.data);
      setisloading(false);
    } catch (error) {
      console.log("Error Fetching Driver Details", error);
      setisloading(false);
    }
  };
  const routeStart = async () => {
    setisloading(true);
    try {
      const routestart = await axios.post(
        `${BASEURL}/RouteStartPoint`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setRouteStartPoint(routestart.data);
      setisloading(false);
    } catch (error) {
      console.log("Error Fetching Driver Details", error);
      setisloading(false);
    }
  };

  const routeend = async () => {
    setisloading(true);
    try {
      const routeend = await axios.post(
        `${BASEURL}/RouteEndPoint`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setRouteEndPoint(routeend.data);
      setisloading(false);
    } catch (error) {
      console.log("Error Fetching Driver Details", error);
      setisloading(false);
    }
  };
  const Contract = async () => {
    setisloading(true);
    try {
      const contract = await axios.post(
        `${BASEURL}/Contractt`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setContractNo(contract.data);
      setisloading(false);
    } catch (error) {
      console.log("Error Fetching Driver Details", error);
      setisloading(false);
    }
  };
  const costoftripexpenses = async () => {
    setLoading2(true);
    try {
      if (seldeliverytripno === "") seldeliverytripno = "null";
      if (selclientname === "") selclientname = "null";
      if (seldrivername === "") seldrivername = "null";
      if (selRouteStart === "") selRouteStart = "null";
      if (selRouteEnd === "") selRouteEnd = "null";
      if (selContractNo === "") selContractNo = "null";
      const cost = await axios.post(
        `${BASEURL}/TripExpenses/${seldeliverytripno}/${selclientname}/${seldrivername}/${selRouteStart}/${selRouteEnd}/${selContractNo}`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      setData(cost.data);
      console.log(cost.data);
      setLoading2(false);
    } catch (error) {
      console.log("Error fetching Fuel Expense Details", error);
      setLoading2(false);
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/TransportDashboard1"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="h2">
        {/* <h2>Cost of Trip Expenses</h2> */}
        <h2>Trip Analytics</h2>
      </div>
      <div className="clearbutton__allPage">
        <button
          className=""
          onClick={() => {
            window.location.reload();
          }}
        >
          Clear
        </button>
      </div>
      <div className="align__date">
        <div>
          <div>
            <p style={{ width: "110px" }}>Start Date :</p>
          </div>
          <div>
            <input
              value={startdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid fromdate");
                } else {
                  setStartdate(e.target.value);
                }
              }}
            />
          </div>

          <div className="trip_end_date">
            <p style={{ width: "110px" }}>End Date :</p>
          </div>
          <div>
            <input
              value={enddate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid todate");
                } else {
                  setEnddate(e.target.value);
                }
              }}
            />
          </div>
        </div>

        <div className="Supp_analytics_Supp_Name">
          <div
            className="analytics_Category_select trip_delivery"
            style={{ zIndex: "100" }}
          >
            <aside>
              <p>Delivery Trip No :</p>
            </aside>
            <Select
              className="Select_item_code_1"
              onChange={(e) => {
                //   if (data.length <= 0) {
                //     alert("There is no data to sort!");
                //   } else {
                // console.log(e.target.value);
                // setselVehicleRegno(e.value);
                seldeliverytripno = e.value;
                costoftripexpenses();

                //   if (e.value === "") {
                // setDataWithVehicleNo([]);
              }}
              defaultValue={
                seldeliverytripno
                  ? { label: seldeliverytripno, value: seldeliverytripno }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...deliverytripno.map((item) => ({
                  value: item["DELIVERY TRIP NO"],
                  label: item["DELIVERY TRIP NO"],
                })),
              ]}
            />
          </div>
        </div>
        <div className="Supp_analytics_Supp_Name">
          <div
            className="analytics_Category_select trip_contract"
            style={{ zIndex: "100" }}
          >
            <aside>
              <p>Contract No :</p>
            </aside>
            <Select
              className="Select_item_code_1"
              onChange={(e) => {
                //   if (data.length <= 0) {
                //     alert("There is no data to sort!");
                //   } else {
                // console.log(e.target.value);
                // setselVehicleRegno(e.value);
                selContractNo = e.value;
                costoftripexpenses();

                //   if (e.value === "") {
                // setDataWithVehicleNo([]);
              }}
              defaultValue={
                selContractNo
                  ? { label: selContractNo, value: selContractNo }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...contractno.map((item) => ({
                  value: item["CONTRACT NO"],
                  label: item["CONTRACT NO"],
                })),
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className="Supp_analytics_Supp_Name_1"
        style={{ marginTop: "-25px" }}
      >
        <div className="analytics_Category_2_select trip_route_start">
          <aside>
            <p>Route Start Point:</p>
          </aside>
          <Select
            className="Select_item_name_1"
            onChange={(e) => {
              selRouteStart = e.value;
              costoftripexpenses();
            }}
            defaultValue={
              selRouteStart
                ? { label: selRouteStart, value: selRouteStart }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...routestartpoint.map((item) => ({
                value: item["ROUTE START POINT"],
                label: item["ROUTE START POINT"],
              })),
            ]}
          />
        </div>
        <div
          className="analytics_Category_2_select trip_route_end"
          style={{ zIndex: "60" }}
        >
          <aside>
            <p>Route End Point :</p>
          </aside>
          <Select
            className="Select_item_name_1"
            onChange={(e) => {
              selRouteEnd = e.value;
              costoftripexpenses();
            }}
            defaultValue={
              selRouteEnd
                ? { label: selRouteEnd, value: selRouteEnd }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...routeendpoint.map((item) => ({
                value: item["ROUTE END POINT"],
                label: item["ROUTE END POINT"],
              })),
            ]}
          />
        </div>
      </div>
      <div
        className="Supp_analytics_Supp_Name_1 "
        style={{ marginTop: "-10px" }}
      >
        <div className="analytics_Category_2_select trip_driver_name">
          <aside>
            <p>Driver Name :</p>
          </aside>
          <Select
            className="Select_item_name_1 driver"
            onChange={(e) => {
              seldrivername = e.value;
              costoftripexpenses();
            }}
            defaultValue={
              seldrivername
                ? { label: seldrivername, value: seldrivername }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...driverName.map((item) => ({
                value: item.DRIVER,
                label: item.DRIVER,
              })),
            ]}
          />
        </div>
        <div
          className="analytics_Category_2_select trip_client_name"
          style={{ zIndex: "40" }}
        >
          <aside>
            <p>Client Name :</p>
          </aside>
          <Select
            className="Select_item_name_1 driver"
            onChange={(e) => {
              selclientname = e.value;
              costoftripexpenses();
            }}
            defaultValue={
              selclientname
                ? { label: selclientname, value: selclientname }
                : { label: "", value: "" }
            }
            options={[
              { value: "", label: "" },
              ...clientName.map((item) => ({
                value: item["CLIENT NAME"],
                label: item["CLIENT NAME"],
              })),
            ]}
          />
        </div>
        {/* <div
          className="analytics_Category_select"
          style={{ marginLeft: "125px", gap: "43px" }}
        >
          <aside>
            <p>Vehicle :</p>
          </aside>
          <Select
            className="Select_item_code_1"
            // onChange={(e) => {
            //   // if (data.length <= 0) {
            //   //   alert("There is no data to sort!");
            //   // } else {
            //   selmake = e.value;
            //   CostoffuelExpenses();
            //   // if (e.value === "") {
            //   //   setDataWithVehicleNo([]);
            //   // }
            // }}
            // defaultValue={
            //   selmake
            //     ? { label: selmake, value: selmake }
            //     : { label: "", value: "" }
            // }
            // options={[
            //   { value: "", label: "" },
            //   ...make.map((item) => ({
            //     value: item.MAKE,
            //     label: item.MAKE,
            //   })),
            // ]}
          />
        </div> */}
      </div>
      <div style={{ width: "80%", margin: "0 auto", marginTop: "40px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {data.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "line-chart", // Change the ID for the line chart
                  },
                  xaxis: {
                    categories: data.map((item) =>
                      moment(item.MONTHNAME).format("MMM-YYYY")
                    ),
                    title: {
                      text: "Months", // X-axis label
                    },
                  },
                  yaxis: {
                    title: {
                      text: "Total Cost", // Y-axis label
                    },
                  },
                  dataLabels: {
                    enabled: false, // Disable the data labels for line chart
                  },
                }}
                series={[
                  {
                    name: "Total Cost",
                    data: data.map((item) => item.TOTALCOST),
                  },
                ]}
                type="line" // Change chart type to "line"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TripExpense;
