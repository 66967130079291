import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../Components/LoadingScreen";
import Loading2 from "../../../Components/Loading2";
import "../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import BreakdownAnalysis from "./WorkshopAnalytics/BreakdownAnalysis";
import TotalWorkshopExpenses from "./WorkshopAnalytics/TotalWorkshopExpenses";
import VehicleJobs from "./WorkshopAnalytics/VehicleJobs";
import VehicleMakewiseExpenses from "./WorkshopAnalytics/VehicleMakewiseExpenses";
import VehicleWiseExpenses from "./WorkshopAnalytics/VehicleWiseExpenses";
import TransportDashboardsNavbar from "../../../Components/TransportDashboardsNavbar";

const Workshop = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "BreakdownAnalysis",
    label: "Analytics Based On Breakdown",
  });
  const options = [
    {
      value: "BreakdownAnalysis",
      label: "Analytics Based On Breakdown Analysis",
    },
    {
      value: "TotalWorkshopExpenses",
      label: "Analytics Based On Total Workshop Expenses",
    },
    {
      value: "VehicleJobs",
      label: "Analytics Based On Vehicle Jobs",
    },

    {
      value: "VehicleMakewiseExpenses",
      label: "Analytics Based On Vehicle Makewise Expenses",
    },
    {
      value: "VehicleWiseExpenses",
      label: "Analytics Based On Vehicle Wise Expenses",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };
  const navigate = useNavigate();
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [VehicleNo, setVehicleNo] = useState([]);
  var [selvehicleNo, setSelVehicleNo] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [data, setData] = useState([]);
  const [datawithVehicleNo, setDataWithVehicleNo] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const [driver, setDriver] = useState([]);
  var [seldrivecode, setselDriveCode] = useState("");
  var [seldrivername, setseldrivername] = useState("");
  const [datawithvehinoanddrivercode, setdatawithvehinoanddrivercode] =
    useState([]);
  const [datawithdrivercode, setDatawithdrivercode] = useState([]);

  useEffect(() => {
    setisloading(false);
  }, [selvehicleNo, fromdate, todate, seldrivecode, seldrivername]);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />
      {/* <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: 9999,
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/TransportDashboard1"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div> */}

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Workshop Analytics</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "BreakdownAnalysis" && (
            <BreakdownAnalysis />
          )}
        {selectedComponent &&
          selectedComponent.value === "TotalWorkshopExpenses" && (
            <TotalWorkshopExpenses />
          )}
        {selectedComponent && selectedComponent.value === "VehicleJobs" && (
          <VehicleJobs />
        )}
        {selectedComponent &&
          selectedComponent.value === "VehicleMakewiseExpenses" && (
            <VehicleMakewiseExpenses />
          )}
        {selectedComponent &&
          selectedComponent.value === "VehicleWiseExpenses" && (
            <VehicleWiseExpenses />
          )}
      </div>
    </div>
  );
};

export default Workshop;
