import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Footer from "../../../../Components/Footer";
import "../../../../CSS/AnalyticsBasedOnNominalActivity.css";
import moment from "moment";
import axios from "axios";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";

function AnalyticsBasedOnNominalActivity() {
  const location = useLocation();

  // const date = location.state.date;
  //   const nominalStartDate = location.state.startDate;
  // const nominalEndDate = location.state.endDate;
  const startdate1 = location.state.startdate;
  const enddate1 = location.state.enddate;
  const [startdate, setStartdate] = useState(
    startdate1
      ? startdate1
      : new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [enddate, setEnddate] = useState(enddate1);
  const [data, setData] = useState([]);

  const accountcode = location.state.value1;
  const accountname = location.state.value2;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setIsloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const handleBackAndReload = () => {
    window.history.back();
  };
  useEffect(() => {
    getDetails();
    setIsloading(false);
  }, [startdate, enddate]);

  const getDetails = () => {
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/NominalActivity`,
        {
          accountcode: accountcode,
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setLoading2(false);
      })
      .catch((error) => {
        setLoading2(false);
        console.log("Error Fetching Profit and Loss Data : ", error);
      });
  };

  const ExcelData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Nominal Activity");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [20, 15, 15, 30, 15, 20, 10, 10, 15, 10, 10]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "TRANSACTION TYPE",
      "VOUCHER NUMBER",
      "NOMINAL LEDGER",
      "NOMINAL NAME",
      "DATE OF TRANSACTION",
      "DESCRIPTION",
      "REFRENCE NUMBER",
      "DOCUMENT NUMBER",
      "AMOUNT",
      "DR",
      "CR",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "NominalActivity.xlsx";
      link.click();
    });
  };

  const PdfData = () => {
    const doc = new jsPDF();

    doc.text("Nominal Activity", 10, 10);

    const columns = [
      "TRANSACTION TYPE",
      "VOUCHER NUMBER",
      "NOMINAL LEDGER",
      "NOMINAL NAME",
      "DATE OF TRANSACTION",
      "DESCRIPTION",
      "REFRENCE NUMBER",
      "DOCUMENT NUMBER",
      "AMOUNT",
      "DR",
      "CR",
    ];
    const table = data.map((data) => [
      data.TRANTYPE,
      data.VOUCHERNO,
      data["NOMINAL LEDGER"],
      data["NOMINAL NAME"],
      data.DATEOFTRANS,
      data.DESCRIPTION,
      data.REFNO,
      data.DOCNO,
      data.AMOUNT,
      data.DR,
      data.CR,
    ]);

    doc.autoTable({
      head: [columns],
      body: table,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("NominalActivity.pdf");
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            <button className="View" onClick={handleBackAndReload}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div>
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          General Ledger
        </h2>
      </div>
      <div className="General_box">
        <div className="General_Second">
          <div className="G_Start_Date">
            <p>
              <b>Start Date:</b>
            </p>
            <input
              className="as_at"
              value={startdate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid startdate");
                } else {
                  setStartdate(e.target.value);
                }
              }}
            ></input>
          </div>
          <div className="G_End_Date">
            <p>
              <b>End Date:</b>
            </p>
            <input
              className="as_at"
              value={enddate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid end date");
                } else {
                  setEnddate(e.target.value);
                }
              }}
            ></input>
          </div>
          {/* <p>Currency</p>
        <input type="text"></input> */}
        </div>
        <div className="General_first">
          <div className="G_acc_code">
            <p>
              <b>Account Code:</b>
            </p>
            <p style={{ color: "red" }}>{accountcode}</p>
          </div>
          <div className="G_account_name">
            <p>
              <b>Account Name :</b>
            </p>
            <p style={{ color: "red" }}>{accountname}</p>
          </div>
        </div>
      </div>
      {/* <div>
        <p>Project :</p>
        <Select className="select_sort_by" options={[]} />
        <input type="chechbox"></input>
        <p>Include year end Journals</p>
        <button>View In Selected Currency</button>
        <p>Exch Rate:</p>
        <input type="text"></input>
      </div> */}
      {/* <div>
        <p>Transaction Type :</p>
        <Select className="select_sort_by" options={[]} />
        <p>Division :</p>
        <Select className="select_sort_by" options={[]} />
        <input type="chechbox"></input>
        <p>View User Defined Fields</p>
      </div> */}
      {isLoading2 ? (
        <Loading2 />
      ) : (
        <>
          <div className="stock_icons">
            <div>
              <RiFileExcel2Line onClick={ExcelData} size={35} />
            </div>
            <div>
              <BsFileEarmarkPdf onClick={PdfData} size={35} />
            </div>
          </div>
          <aside className="aside">
            <div
              style={{
                width: "95%",

                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td>TRANSACTION TYPE</td>
                    <td className="header_gl_voucher_no">VOUCHER NO</td>
                    {/* <td className="header_nominal_ledger">NOMINAL LEDGER</td>
                    <td className="header_nominal_name">NOMINAL NAME</td> */}
                    <td className="header_gl_date">DATE</td>
                    <td className="header_gl_desc">DESCRIPTION</td>
                    <td className="header_gl_ref_no">REF NO</td>
                    <td className="header_gl_doc_no">DOC NO</td>
                    <td className="header_gl_dr">DR</td>
                    <td className="header_gl_cr">CR</td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {data.map((data) => {
                    return (
                      <tr className="tr_test2">
                        <td className="value_trans_type">{data.TRANTYPE}</td>
                        <td className="value_gl_voucher_no">
                          {data.VOUCHERNO}
                        </td>
                        {/* <td className="value_nominal_ledger">
                          {data["NOMINAL LEDGER"]}
                        </td>
                        <td className="value_nominal_name">
                          {data["NOMINAL NAME"]}
                        </td> */}
                        <td className="value_gl_date">
                          {moment(data.DATEOFTRANS).format("DD/MM/YYYY")}
                        </td>
                        <td className="value_gl_desc">{data.DESCRIPTION}</td>
                        <td className="value_gl_ref_no">{data.REFNO}</td>
                        <td className="value_gl_doc_no">{data.DOCNO}</td>
                        <td className="value_gl_dr">
                          {data.DR.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="value_gl_cr">
                          {data.CR.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </aside>
        </>
      )}
      <Footer />
    </div>
  );
}

export default AnalyticsBasedOnNominalActivity;
