import React, { useEffect, useState, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  selectUser,
  setSelectedDepartment,
  resetData,
} from "../features/userSlice";
import "./ClientNavbar.css";
const MasterNavbar = () => {
  const navigate = useNavigate();
  const navbarRef = useRef(null);
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const userEmailToSHOW = user.email?.split("@")[0].toUpperCase();
  console.log(userEmail);
  console.log(user);
  const module = user?.selectedDepartment;
  console.log(module);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const state = useSelector((state) => state.loginreducer);
  console.log("state state", state);

  //console.log(state);
  const userName = state?.user?.emailId?.split("@")[0].toUpperCase();
  const email = state?.user?.emailId;

  const modulename = state?.user?.traineeOrTrainer;
  //console.log(email, modulename);
  const select = useSelector((state) => state.SelCompany);
  const selectedCompany = select?.selectedCompany;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [isloading, setIsLoading] = useState(true);
  const [ModuleAccess, setModuleAccess] = useState([]);

  const [openGroup, setOpenGroup] = useState(null);

  const handleGroupClick = (index) => {
    setOpenGroup(openGroup === index ? null : index);
  };

  function AuthorizseUserLogin() {
    //console.log(email);
    //console.log(modulename);
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: userEmail,
          MODULENAME: "MARS DASHBOARDS",
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        //console.log(response?.data);
        setModuleAccess(response?.data);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    AuthorizseUserLogin();
  }, []);
  const dispatch = useDispatch();
  function logOut() {
    navigate("/");
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // setIsDeliveryNoteClicked(false);
  };

  const handleNavigation = (page) => {
    navigate(`/${page.link}`, { state: { menu: page.menu } });
  };

  useEffect(() => {
    ///menu close anywhere click
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <nav className="centernav" ref={navbarRef}>
      <div className="navbar-container uppercase">
        <nav className="">
          <div className="">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <ul
            className={`nav-menu ${isMenuOpen ? "open" : ""}`}
            style={{ textTransform: "uppercase" }}
          >
            {/* <li className="nav-item">Men</li> */}

            <div className="hover-dropdown">
              {/* <span className="master-select-opt hover-underline-animation">
                <label className="transaction-select hover-underline-animation">
                  {ModuleAccess.length > 0 ? "Menu" : "Scanner Application"}
                </label>
              </span> */}

              <ul className="ul__list__style__scanner">
                {ModuleAccess.length === 0 ? (
                  <div>
                    <li>{isloading ? "LOADING...." : "NO RIGHTS"}</li>
                  </div>
                ) : null}

                <aside>
                  {ModuleAccess.reduce((acc, item, index) => {
                    // Check if MENU_TYPE already exists in the accumulator
                    const existingType = acc.find(
                      (group) => group.type === item.OM_ACCOUNT_MODULE_MENU_TYPE
                    );

                    if (existingType) {
                      // If MENU_TYPE exists, add the current ID to its list
                      if (typeof item === "object") {
                        existingType.ids.push({
                          name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                          link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                          menu: item.OM_ACCOUNT_MODULE_MENU,
                        });
                        existingType.weblink.push(
                          item.OM_ACCOUNT_MODULE_WEB_PAGE
                        );
                      } else {
                        existingType.ids.push(item);
                        existingType.weblink.push(existingType.weblink[index]);
                      }
                    } else {
                      // If MENU_TYPE doesn't exist, create a new group with the current ID
                      const newId =
                        typeof item === "object"
                          ? {
                              name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                              link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                              menu: item.OM_ACCOUNT_MODULE_MENU,
                            }
                          : item;

                      acc.push({
                        type: item.OM_ACCOUNT_MODULE_MENU_TYPE,
                        ids: [newId],
                        weblink: [item.OM_ACCOUNT_MODULE_WEB_PAGE],
                      });
                    }
                    return acc;
                  }, []).map((group, index) => (
                    <div key={index} style={{ fontSize: "14px" }}>
                      <p
                        style={{
                          cursor: "pointer",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                        onClick={() => handleGroupClick(index)}
                      >
                        {group.type}
                      </p>

                      {openGroup === index && (
                        <ul
                          style={{ listStyleType: "none", paddingLeft: "15px" }}
                        >
                          {group.ids.map((id, idIndex) => (
                            <li key={idIndex} style={{ cursor: "pointer" }}>
                              {typeof id === "object" && (
                                <span
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleNavigation(id)}
                                >
                                  {id.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </aside>
              </ul>
            </div>
          </ul>
        </nav>
        <div className="logedIn__as__btn">
          <h2> WELCOME to Tech23</h2>
          <h3 className="hidden lg:block">
            Logged in as : {userEmailToSHOW ? userEmailToSHOW : "N/A"}
          </h3>
          {/* {state.emailId.split("@")[0].toUpperCase()}</h3> */}
        </div>

        <aside>
          <button onClick={logOut} className="button__logout__navbar">
            {" "}
            LOGOUT
          </button>
        </aside>
        <div className="logout__fullscren">
          <button onClick={logOut} className="log__out__icon">
            {" "}
            <FiLogOut />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default MasterNavbar;
