import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const ClientwiseSales = () => {
  const [isloading, setisloading] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading2, setLoading2] = useState(false);
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [activeDates, setActiveDates] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [selectedDateRange, setSelectedDateRange] = useState(null); // Define selectedDateRange
  const [dateRange, setDateRange] = useState([
    // Define dateRange
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    AnalyzeBasedOnClient();
  }, [fromdate, todate]);

  const AnalyzeBasedOnClient = () => {
    setLoading2(true);
    const startDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log("startDatePass", startDatePass, "endDatePass", endDatePass);
    if (startDatePass && endDatePass) {
      axios
        .post(
          `${BASEURL}/clientwiseSales`,
          {
            FROMDATE: startDatePass,
            TODATE: endDatePass,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const options = [
    { value: "last7days", label: "Last 7 Days" },
    { value: "last3days", label: "Last 3 Days" },
    { value: "last30days", label: "Last 30 Days" },
    { value: "lastMonth", label: "Last Month" },
    { value: "lastQuarter", label: "Last Quarter" },
    { value: "lastYear", label: "Last Year" },
    { value: "custom", label: "Custom Date" }, // Custom date option
  ];

  const filterRef = useRef(null); // Reference to the filter div

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  //
  const filteredData = selectedRoute
    ? selectedRoute.value === "" // Check if "All" is selected
      ? data // Return all data if "All" is selected
      : data.filter((item) => item?.CLIENT === selectedRoute.value)
    : data;
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>

          <p className="mt-2  lg:mt-0 lg:ml-[100px]">CLIENT &nbsp;</p>
          <div>
            <Select
              className="route_select"
              value={selectedRoute}
              onChange={(selectedOption) => {
                setSelectedRoute(selectedOption);
              }}
              options={[
                { value: "", label: "All" }, // Option to return all data
                ...data.map((item) => ({
                  value: item?.CLIENT,
                  label: item?.CLIENT,
                })),
              ]}
            />
          </div>
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <div style={{ width: "95%", margin: "0 auto", marginTop: "15px" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {data && filteredData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>
                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                      },
                      xaxis: {
                        categories: filteredData.map((item) => item?.CLIENT),
                        title: {
                          text: "CLIENT",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "SALES",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "SALES",
                        data: filteredData.map((item) => item?.SALES),
                      },
                    ]}
                    type="bar"
                    height={300}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                        type: "area", // Set the chart type to "area"
                      },
                      xaxis: {
                        categories: filteredData.map((item) => item?.CLIENT), // X-axis categories
                        title: {
                          text: "CLIENT", // X-axis label
                        },
                      },
                      yaxis: {
                        title: {
                          text: "SALES", // Y-axis label
                        },
                      },
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "SALES",
                        data: filteredData.map((item) => item?.SALES),
                      },
                    ]}
                    type="area" // Set chart type to "area"
                    height={300}
                  />
                )}

                {chartType === "pie" && (
                  <ReactApexChart
                    options={{
                      labels: filteredData.map((item) =>
                        item?.CLIENT?.slice(0, 14)
                      ),
                      title: {
                        text: "Pie Chart",
                      },
                      dataLabels: {
                        enabled: true,
                      },
                      tooltip: {
                        enabled: true,
                        y: {
                          formatter: function (val, opts) {
                            let index = opts.dataPointIndex;
                            let name = "TOTAL SALES ";
                            return name + ": " + val;
                          },
                        },
                      },
                    }}
                    series={filteredData.map((item) => item?.SALES)}
                    type="pie"
                    height={300}
                  />
                )}
                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart",
                      },
                      xaxis: {
                        type: "CLIENT", // Change the type to 'category' if CATEGORY represents discrete categories
                        labels: {
                          formatter: function (val) {
                            return val; // Use val directly as the formatter for category labels
                          },
                          rotate: 0, // Adjust the rotation of the labels if needed
                        },
                        title: {
                          text: "CLIENT",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "SALES",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "SALES",
                        data: filteredData.map((item) => ({
                          x: item?.CLIENT,
                          y: item?.SALES,
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO DATA AVAILABLE
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ClientwiseSales;
